import React from 'react';
import styled from 'styled-components';
import RecordIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import { startRecording, stopRecording } from '../actions/callActions';

const StyledButton = styled(Button)`
  && {
    font-size: 12px;
    font-weight: 400;
  }
`;

const StyledRecordIcon = styled(RecordIcon)`
  && {
    font-size: 18px;

    &.recording {
      color: #fff;
      animation-name: pulse; 
      animation-duration: 1s; 
      animation-timing-function: ease-out; 
      animation-direction: alternate; 
      animation-iteration-count: infinite; 
      animation-play-state: running;
    
      @keyframes pulse { 
        0% { 
          opacity: .5
        } 
    
        100% { 
          opacity: 1;
        } 
      }
    }
  }
`;

const RecordingButton = ({ t, actions, canRecord, isRecording = false }) => {
  if (!canRecord) {
    return null;
  }

  return (
    <StyledButton
      color={isRecording ? 'error' : 'primary'}
      onClick={isRecording ? actions.stopRecording : actions.startRecording}
      size="small"
      startIcon={<StyledRecordIcon className={isRecording ? 'recording' : ''} />}
      variant="contained"
    >
      {isRecording ? t('stopRecording') : t('startRecording')}
    </StyledButton>
  );
};

const mapStateToProps = state => ({
  canRecord: state.user.session?.profile?.onlineCallRecordEnabled,
  isRecording: state.call.currentCallSession?.isRecording?.(),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ startRecording, stopRecording }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('call')(RecordingButton)));
