import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import HeadsetMic from '@mui/icons-material/HeadsetMic';
import { useLocation } from 'react-router-dom';

import ButtonWithIcon from '../../main/components/ButtonWithIcon';
import { PRESENTIAL_COLORS } from '../../main/reducers/mainReducer';

const { RED, GREEN, YELLOW } = PRESENTIAL_COLORS;

const Status = styled.div`
  position: absolute;
  right: 5px;
  bottom: 1px;
  height: 11px;
  width: 11px;
  border-radius: 6px;
  background: ${props => props.color};
  pointer-events: none;
`;

const AgentState = ({ hasAgentAccess, hasCallCenterRight, active, connected }) => {
  if (!hasAgentAccess) {
    return null;
  }

  const location = useLocation();
  let color = RED;
  if (connected) {
    color = active ? GREEN : YELLOW;
  }

  return (
    <ButtonWithIcon active={location.pathname.indexOf('agent') !== -1} to="/agent">
      <HeadsetMic />
      {hasCallCenterRight && <Status color={color} />}
    </ButtonWithIcon>
  );
};

const mapStateToProps = state => ({
  hasAgentAccess: state.user.session?.profile?.agent?.id,
  hasCallCenterRight: state.user.session?.profile?.subscriptionType === 3,
  connected: state.agent.connected,
  active: state.agent.active,
});

export default connect(
  mapStateToProps,
)(React.memo(AgentState));
