import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import WarningIcon from '@mui/icons-material/Warning';

import { hideError } from '../actions/mainActions';
import colors from '../themes/colors';

const StyledSnackbar = styled(Snackbar)`
  && {
    width: 90%;
  }
`;
const StyledSnackbarContent = styled(SnackbarContent)`
  && {
    min-width: 80%;
    background-color: ${colors.error};
  }
`;
const MessageContent = styled.span`
  display: flex;
  align-items: center;
`;

const Icon = styled(WarningIcon)`
  margin-right: 10px;
`;

const ErrorMessage = ({ error, actions }) => {
  if (!error) {
    return null;
  }

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={actions.hideError}
      open={Boolean(error)}
    >
      <StyledSnackbarContent
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={actions.hideError}>
            <CloseIcon />
          </IconButton>,
        ]}
        message={
          <MessageContent>
            <Icon />
            {error}
          </MessageContent>
        }
        variant="body1"
      />
    </StyledSnackbar>
  );
};

const mapStateToProps = state => ({
  error: state.main.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ hideError }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ErrorMessage));
