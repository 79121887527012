import {
  STORE_CARD_ID,
  SKIP_CARD,
  CREATE_OR_UPDATE_CARD_SUCCESS,
  CARD_SET_VALUE,
  ON_MULTIPLE_LINKED_ENTITIES_FOUND,
  ON_ENTITIES_FOUND,
  ON_SAVING_CARD,
  ON_SAVED_CARD,
} from '../actions/cardActions';
import { CALL_MADE, CALL_NUMBER, ON_INVITE, UPDATE_CALL_SESSION } from '../../call/actions/callActions';
import { SEARCH_ENTITIES } from '../actions/cardActions';
import { LINKED_CONTACT_FIELD, LINKED_RELATION_FIELD } from '../containers/EntitySelection';

const initialState = {
  cardId: null,
  savingCard: false,
  // The current callSession that is not trashed at the end of the call
  // useful to call `createOrUpdateTask` when the card is saved and the call ended.
  callSession: null,
  searching: {},
  linkedEntities: {},
  searchedEntities: {},
  content: {
    [LINKED_CONTACT_FIELD]: null,
    [LINKED_RELATION_FIELD]: null,
    title: '',
    note: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ON_INVITE:
    case CALL_MADE:
      return { ...initialState, callSession: action.payload.callSession };

    case UPDATE_CALL_SESSION:
      // Updating currentCallSession so we can have `answerTime` or `callId`
      if (!action.payload.callSession) {
        return state;
      }
      return { ...state, callSession: action.payload.callSession };

    case STORE_CARD_ID:
      return { ...state, cardId: action.payload.id };

    case ON_MULTIPLE_LINKED_ENTITIES_FOUND:
      return {
        ...state,
        linkedEntities: {
          ...state.linkedEntities,
          [action.payload.fieldId]: action.payload.linkedEntities,
        },
      };

    case CALL_NUMBER:
    case SKIP_CARD:
    case CREATE_OR_UPDATE_CARD_SUCCESS:
      return initialState;

    case CARD_SET_VALUE:
      return { ...state, content: { ...state.content, [action.payload.name]: action.payload.value } };

    case ON_SAVING_CARD:
      return { ...state, savingCard: true };

    case ON_SAVED_CARD:
      return { ...state, savingCard: false };

    case SEARCH_ENTITIES:
      return {
        ...state,
        searching: {
          ...state.searching,
          [action.payload.fieldId]: true,
        },
      };

    case ON_ENTITIES_FOUND:
      return {
        ...state,
        searching: false,
        searchedEntities: {
          ...state.searchedEntities,
          [action.payload.fieldId]: action.payload.entities,
        },
      };

    default:
      return state;
  }
}
