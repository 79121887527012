export default {
  username: 'Identifiant',
  password: 'Mot de passe',
  next: 'Suivant',
  login: 'Connexion',
  wrongCredentials: 'Identifiant ou mot de passe incorrect',
  errorOccurred: 'Une erreur est survenue',
  authFailure: 'Erreur d\'authentification',
  noAuthorization: 'Ce compte n\'a pas accès à l\'application',
  ldapConnection: 'Connexion avec LDAP',
  ldapLogin: 'Connexion avec LDAP',

  userState: 'Statut',
  userStatus: 'Statut',
  available: 'Disponible',
  doNotDisturb: 'Ne pas déranger',
  notAvailable: 'Indisponible',
  addStatus: 'Ajouter un statut...',
};
