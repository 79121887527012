import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import { ButtonBase } from '@mui/material';
import classnames from 'classnames';

import longPress from '../../main/utils/longPress';
import colors from '../../main/themes/colors';

const keys = [
  { digit: '1', description: '' },
  { digit: '2', description: 'ABC' },
  { digit: '3', description: 'DEF' },
  { digit: '4', description: 'GHI' },
  { digit: '5', description: 'JKL' },
  { digit: '6', description: 'MNO' },
  { digit: '7', description: 'PQRS' },
  { digit: '8', description: 'TUV' },
  { digit: '9', description: 'WXYZ' },
  { digit: '*', description: '' },
  { digit: '0', description: '+' },
  { digit: '#', description: '' },
];

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 134px);
`;

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-width: 300px;

  @media(min-width: 400px) {
    padding: 0 10%;
  }
`;

const Key = styled(ButtonBase)`
  && {
    min-height: 80px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 33%;
    border-bottom: 1px solid ${colors.divider};
    border-right: 1px solid ${colors.divider};

    @media(min-height: 700px) {
      min-height: 15vh;
    }
    
    &.noRightBorder {
      border-right: none;
    }

    &.noBottomBorder {
      border-bottom: none;
    }
  
    &:hover span {
      font-weight: 400;
    }
  }
`;

const Digit = styled(Typography)`
  && {
    padding-top: 8px;
    color: ${colors.button};
    font-size: 32px;
    user-select: none;
    line-height: 1em;
  }
`;

const Description = styled(Typography)`
  && {
    color: ${colors.button};
    font-size: 14px;
    letter-spacing: .2em;
    opacity: .6;
    min-height: 16px;
  }
`;

const Keys = ({ number, onChange, sendDTMF }) => {
  const onClick = digit => () => {
    sendDTMF && sendDTMF(digit);
    onChange(number + digit);
  };

  const onLongPress = digit => () => {
    const value = digit === '0' ? '+' : digit;

    sendDTMF && sendDTMF(value);
    onChange(number + value);
  };

  return (
    <Container>
      <Content>
        {keys.map((key, index) => {
          const classes = classnames({
            'keypad-key': true,
            noRightBorder: index % 3 === 2,
            noBottomBorder: index > 8,
          });
          return (
            <Key key={key.digit} className={classes} {...longPress(onLongPress(key.digit), onClick(key.digit), 700)}>
              <Digit>{key.digit}</Digit>
              <Description>{key.description}</Description>
            </Key>
          );
        })}
      </Content>
    </Container>
  );
};

export default React.memo(Keys);
