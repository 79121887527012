import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from './main/themes/muiTheme';
import wazoTheme from './main/themes';
import configureStore from './main/reducers/configureStore';
import history from './main/router/history';
import WazoRouter from './main/router/WazoRouter';
import Login from './user/containers/Login';
import Dialer from './dialer/containers/Dialer';
import TabBar from './main/containers/TabBar';
import ErrorMessage from './main/containers/ErrorMessage';
import Settings from './settings/containers/Settings';
import UserState from './user/containers/UserState';
import Language from './settings/containers/Language';
import Information from './settings/containers/Information';
import Call from './call/containers/Call';
import Transfer from './dialer/containers/Transfer';
import Agent from './agent/containers/Agent';
import Contacts from './contact/containers/Contacts';
import CallLogs from './callLogs/containers/CallLogs';
import MainContainer from './main/containers/MainContainer';
import './i18n';
import './main/themes/App.css';

const store = configureStore();

const UnConnectedRouter = ({ authenticated }) => (
  <WazoRouter history={history}>
    {!authenticated ? (
      <Routes>
        <Route element={<Login />} index path="/" />
        <Route element={<Navigate replace to="/" />} path="*" />
      </Routes>
    ) : (
      <MainContainer>
        <Routes>
          <Route element={<Dialer />} exact path="/dialer" />
          <Route element={<Agent />} exact path="/agent" />
          <Route element={<Settings />} exact path="/settings" />
          <Route element={<UserState />} exact path="/user-state" />
          <Route element={<Language />} exact path="/language" />
          <Route element={<Information />} exact path="/information" />
          <Route element={<Call />} exact path="/call" />
          <Route element={<Transfer />} exact path="/transfer" />
          <Route element={<Contacts />} exact path="/contacts" />
          <Route element={<CallLogs />} exact path="/call-logs" />
        </Routes>
        <TabBar />
        <ErrorMessage />
      </MainContainer>
    )}
  </WazoRouter>
);

const ConnectedRouter = connect(state => ({
  authenticated: state.user.authenticated,
}))(React.memo(UnConnectedRouter));

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={wazoTheme}>
      <Provider store={store}>
        <ConnectedRouter />
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>
);

export default React.memo(App);
