export const sendParentMessage = (type, payload) => {
  if (window !== window.parent) {
    window.parent.postMessage({ type, ...payload }, '*');
  }
};

export const sendMessage = (type, payload) => {
  window.postMessage({ type, ...payload }, '*');
  sendParentMessage(type, payload);
};
