import { CHANGE_DIALER_VALUE } from '../actions/dialerActions';
import { HANGUP_CALL, CALL_ENDED } from '../../call/actions/callActions';

const initialState = {
  keypadNumber: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_DIALER_VALUE:
      return { ...state, keypadNumber: action.payload.value };

    case CALL_ENDED:
    case HANGUP_CALL:
      return { ...state, keypadNumber: '' };

    default:
      return state;
  }
}
