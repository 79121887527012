import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import colors from '../themes/colors';

const Container = styled.header`
  background: ${colors.secondGrey};
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Label = styled(Typography)`
  && {
    font-size: 24px;
    margin-bottom: 10px;
    color: ${colors.headerColor};
  }
`;

const Header = ({ label }) => {
  return (
    <Container>
      <Label>{label}</Label>
    </Container>
  );
};

export default Header;
