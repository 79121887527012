export default {
  primary: '#203890',
  button: '#292C87',
  black: '#000',
  white: '#fff',
  greenButton: '#7ed865',
  greenButtonHover: '#6ebf5a',
  redButton: '#FA5846',
  redButtonHover: '#FF604F',
  buttonText: '#fff',
  error: '#E80539',
  secondary: '#203890',
  grey: '#8A95A0',
  secondGrey: '#eee',
  headerColor: '#888',
  avatar: '#bdbdbd', // @FIXME: import from MUI
  divider: 'rgba(0, 0, 0, 0.12)', // @FIXME: import from MUI
  placeholder: 'rgba(22, 44, 66, 0.5)',
  hovered: '#6181F4',
};
