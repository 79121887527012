export default {
  title: 'Titre',
  note: 'Note',
  skip: 'Passer',
  cancel: 'Annuler',
  save: 'Sauver',
  entity: 'Lié au contact',
  relation: 'Relié à',
  returnToCard: 'Retourner à la note',
  suggested: 'Suggestion',
  others: 'Autre',
  firstname: 'Prénom',
  lastname: 'Nom',
  name: 'Nom',
  phone: 'Téléphone',
  addContact: 'Ajouter un contact',
  mandatory: 'Ce champ est obligatoire',
  validateNewContact: 'Valider',
};
