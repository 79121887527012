import { takeEvery } from 'redux-saga/effects';
import i18n from 'i18next';

import { UPDATE_LANGUAGE } from '../actions/mainActions';

function* updateLanguage({ payload: { language } }) {
  i18n.changeLanguage(language);
  localStorage.setItem('language', language);
}

export default [
  takeEvery(UPDATE_LANGUAGE, updateLanguage),
];
