import Wazo from '@wazo/sdk/lib/simple';
import { parsePhoneNumber } from '@wazo/sdk/lib/utils/PhoneNumberUtil';
import { put, takeEvery, select, call } from 'redux-saga/effects';

import { LOGIN_SUCCESS, AUTHENTICATION_SUCCESS } from '../../user/actions/userActions';
import {
  call as callAction,
  onInvite,
  onCallError,
  onCallMade,
  onCallEnded,
  onCallHeld,
  onCallResumed,
  onCallAccepted,
  onCallProgress,
  onCallMuted,
  onCallUnMuted,
  updateCallSession,
  onIndirectTransferCallMade,
  onIndirectTransferCallUpdated,
  onIndirectTransferDone,
  hold,
  unhold,
  CALL_MADE,
  CALL_NUMBER,
  CALL_SEND_DTMF,
  HANGUP_CALL,
  ON_INVITE,
  REJECT_CALL,
  CALL_HOLD,
  CALL_RESUME,
  CALL_MUTE,
  CALL_UNMUTE,
  CALL_LOCALLY_ACCEPTED,
  CALL_ENDED,
  CALL_REMOTLY_ACCEPTED,
  DIRECT_TRANSFER,
  CREATE_INDIRECT_TRANSFER,
  CANCEL_INDIRECT_TRANSFER,
  CONFIRM_INDIRECT_TRANSFER,
  START_RECORDING,
  STOP_RECORDING,
  START_LISTENING_CALL_EVENTS,
} from '../actions/callActions';
import { ON_CLICK_TO_CALL } from '../../main/actions/bridgeActions';
import history from '../../main/router/history';
import { getTranslator } from '../../i18n';
import sounds from '../../main/themes/sounds';
import SoundPlayer from '../../main/service/SoundPlayer';
import { bindEvents } from '../../main/utils/sagas';
import { fetchOwnContact } from '../../contact/actions/contactActions';
import { integration } from '../../user/sagas/userSagas';

const t = getTranslator('call');

function* handleCallEnded(callSession) {
  const {
    call: { indirectTransferCall, currentCallSession, accepted, locallyAccepted },
  } = yield select();

  if (callSession.is(indirectTransferCall)) {
    yield indirectTransferDone();
  }

  if (callSession.is(currentCallSession)) {
    // When the remote party hangups, we have to hangup the indirect transfer call
    if (indirectTransferCall) {
      Wazo.Phone.hangup(indirectTransferCall);
    }
    callSession.answered = accepted || locallyAccepted;
    // Update callSession with endTime
    yield put(updateCallSession(callSession));

    // Trigger integration events
    yield put(onCallEnded(callSession));

    // Remove call from the reducer
    yield put(updateCallSession(null));
  }
}

function* isInCall() {
  const {
    call: { ringing, inCall },
  } = yield select();

  return ringing || inCall;
}

function* isWritingCard() {
  const {
    card: { cardId },
    main: { hasCard },
  } = yield select();

  return hasCard && !!cardId;
}

const phoneEvents = {
  [Wazo.Phone.ON_CALL_ANSWERED]: function*(callSession) {
    const {
      call: { currentCallSession },
    } = yield select();
    // Do not update current call with other call (like transfer call)
    if (currentCallSession.is(callSession)) {
      return;
    }
    yield put(updateCallSession(callSession));
  },
  [Wazo.Phone.ON_CALL_ACCEPTED]: function*(callSession) {
    const {
      call: { currentCallSession, indirectTransferCall },
    } = yield select();
    // Do not update current call with other call (like transfer call)
    if (currentCallSession.is(callSession)) {
      yield put(updateCallSession(callSession));
      yield put(onCallAccepted(callSession));
    } else if (indirectTransferCall && indirectTransferCall.is(callSession)) {
      yield put(onIndirectTransferCallUpdated(callSession));
    }
  },
  [Wazo.Phone.ON_CALL_INCOMING]: function*(callSession) {
    const inCall = yield isInCall();
    const writingCard = yield isWritingCard();
    // Do not accept other call when already in call or when writing a card
    if (inCall || writingCard) {
      return Wazo.Phone.hangup(callSession);
    }

    yield put(onInvite(callSession));
    return yield call(history.push, '/call');
  },
  [Wazo.Phone.ON_PROGRESS]: function*() {
    yield put(onCallProgress());
  },
  [Wazo.Phone.ON_CALL_MUTED]: function*() {
    yield put(onCallMuted());
  },
  [Wazo.Phone.ON_CALL_UNMUTED]: function*() {
    yield put(onCallUnMuted());
  },
  [Wazo.Phone.ON_CALL_FAILED]: function*(callSession) {
    yield handleCallEnded(callSession);
  },
  [Wazo.Phone.ON_CALL_REJECTED]: function*(callSession) {
    yield handleCallEnded(callSession);
  },
  [Wazo.Phone.ON_CALL_CANCELED]: function*(callSession) {
    yield handleCallEnded(callSession);
  },
  [Wazo.Phone.ON_CALL_ENDED]: function*(callSession) {
    yield handleCallEnded(callSession);
  },
};

const callWsEvents = {
  [Wazo.Websocket.CALL_UPDATED]: function* ({ data }) {
    const {
      call: { currentCallSession },
    } = yield select();
    if (currentCallSession?.sipCallId === data.sip_call_id) {
      const updatedCallSession = Wazo.domain.CallSession.parseCall(Wazo.domain.Call.parse(data));

      currentCallSession.updateFrom(updatedCallSession);
      yield put(updateCallSession(currentCallSession));
    }
  },
};

function* onListenToCallEvents() {
  yield bindEvents(callWsEvents, Wazo.Websocket);
}

function* createPhone() {
  yield Wazo.Phone.connect({
    userAgentString: `softphone-${integration}`,
  });

  // Fetch contact here to have a real `line_state` value
  yield put(fetchOwnContact());

  yield bindEvents(phoneEvents, Wazo.Phone);
}

function* callNumber({ payload: { number } }) {
  try {
    const callSession = yield Wazo.Phone.call(parsePhoneNumber(number));
    yield put(onCallMade(callSession));
    yield call(history.push, '/call');
  } catch (e) {
    onCallError(t('callError'), e);
  }
}

function* redirectAfterCallEnded({ payload }) {
  const {
    main: { hasCard },
  } = yield select();

  const { callSession } = payload;

  if (hasCard && callSession.answered) {
    // Return to call (useful for direct transfer)
    yield call(history.push, '/call');
    return;
  }

  yield call(history.push, '/dialer');
}

function playProgressSound() {
  SoundPlayer.playSound(sounds.progress);
}

function playInviteSound() {
  SoundPlayer.playSound(sounds.ring);
}

function stopSound() {
  SoundPlayer.stop();
}

function* onClickToCall({ payload: { number } }) {
  const inCall = yield isInCall();
  const writingCard = yield isWritingCard();

  // Double call is not supported yet, nor calling when a card is not yet saved
  if (inCall || writingCard) {
    return;
  }

  yield put(callAction(number));
}

function* hangup() {
  const {
    call: { currentCallSession, indirectTransferCall },
  } = yield select();

  // Hangup indirect transfer
  if (indirectTransferCall) {
    try {
      yield Wazo.Phone.hangup(indirectTransferCall);
    } catch (e) {
      // Nothing to do
    }
  }

  try {
    yield Wazo.Phone.hangup(currentCallSession);
  } catch (e) {
    // Nothing to do
  }
}

function* muteCall() {
  const {
    call: { currentCallSession, indirectTransferCall },
  } = yield select();

  // Mute is global, so we have to mute the indirect call transfer also
  if (indirectTransferCall) {
    Wazo.Phone.mute(indirectTransferCall);
  }
  Wazo.Phone.mute(currentCallSession);

  yield put(onCallMuted());
}

function* unmuteCall() {
  const {
    call: { currentCallSession, indirectTransferCall },
  } = yield select();

  // Mute is global, so we have to unmute the indirect call transfer also
  if (indirectTransferCall) {
    Wazo.Phone.unmute(indirectTransferCall);
  }

  Wazo.Phone.unmute(currentCallSession);

  yield put(onCallUnMuted());
}

function* holdCall() {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.hold(currentCallSession);
  yield put(onCallHeld());
}

function* resumeCall() {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.resume(currentCallSession);
  yield put(onCallResumed());
}

function* sendDTMF({ payload: { value } }) {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.sendDTMF(value, currentCallSession);
}

function* directTransfer({ payload: { number } }) {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.transfer(currentCallSession, number);
}

function* indirectTransfer({ payload: { number } }) {
  const {
    call: { muted },
  } = yield select();

  // Hold active call
  yield put(hold());

  const callSession = yield Wazo.Phone.call(number);

  // Mute is global, so we have to mute the transfer call too
  if (muted) {
    Wazo.Phone.once(Wazo.Phone.ON_CALL_ACCEPTED, () => {
      Wazo.Phone.mute(callSession);
    });
  }

  yield put(onIndirectTransferCallMade(callSession));
}

function* cancelIndirectTransfer() {
  const {
    call: { indirectTransferCall },
  } = yield select();

  yield indirectTransferDone();

  // Hangup call transfer
  Wazo.Phone.hangup(indirectTransferCall);
}

function* indirectTransferDone() {
  // Resume active call
  yield put(unhold());

  // Redirect to call
  yield call(history.push, '/call');

  yield put(onIndirectTransferDone());
}

function* confirmIndirectTransfer() {
  const {
    call: { currentCallSession, indirectTransferCall },
  } = yield select();

  const indirectSipSesion = Wazo.Phone.phone.findSipSession(indirectTransferCall);
  const currentSipSession = Wazo.Phone.phone.findSipSession(currentCallSession);
  if (!currentSipSession || !indirectSipSesion) {
    return;
  }

  indirectSipSesion.refer(currentSipSession);

  // Redirect to call
  yield call(history.push, '/call');

  yield put(onIndirectTransferDone());
}

function* acceptCall() {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.accept(currentCallSession);
}

function* rejectCall() {
  const {
    call: { currentCallSession },
  } = yield select();

  Wazo.Phone.hangup(currentCallSession);
}
function* callRecordMethod(method) {
  const {
    call: { currentCallSession },
  } = yield select();

  const callId = currentCallSession.callId;

  try {
    yield Wazo.api.calld[method](callId);
  } catch (_) {
    // Nothing to do
  }
}

function* onStartRecording() {
  yield callRecordMethod('startRecording');
}

function* onStopRecording() {
  yield callRecordMethod('stopRecording');
}

export default [
  takeEvery(LOGIN_SUCCESS, createPhone),
  takeEvery(START_LISTENING_CALL_EVENTS, onListenToCallEvents),
  takeEvery(AUTHENTICATION_SUCCESS, createPhone),
  takeEvery(CALL_NUMBER, callNumber),
  takeEvery(ON_CLICK_TO_CALL, onClickToCall),
  takeEvery(HANGUP_CALL, hangup),
  takeEvery(CALL_MADE, playProgressSound),
  takeEvery(ON_INVITE, playInviteSound),
  takeEvery(CALL_MUTE, muteCall),
  takeEvery(CALL_UNMUTE, unmuteCall),
  takeEvery(CALL_HOLD, holdCall),
  takeEvery(CALL_RESUME, resumeCall),
  takeEvery(CALL_SEND_DTMF, sendDTMF),
  takeEvery(DIRECT_TRANSFER, directTransfer),
  takeEvery(CREATE_INDIRECT_TRANSFER, indirectTransfer),
  takeEvery(CONFIRM_INDIRECT_TRANSFER, confirmIndirectTransfer),
  takeEvery(CANCEL_INDIRECT_TRANSFER, cancelIndirectTransfer),
  takeEvery(CALL_LOCALLY_ACCEPTED, acceptCall),
  takeEvery(CALL_LOCALLY_ACCEPTED, stopSound),
  takeEvery(CALL_REMOTLY_ACCEPTED, stopSound),
  takeEvery(REJECT_CALL, rejectCall),
  takeEvery(REJECT_CALL, stopSound),
  takeEvery(CALL_ENDED, redirectAfterCallEnded),
  takeEvery(CALL_ENDED, stopSound),
  takeEvery(START_RECORDING, onStartRecording),
  takeEvery(STOP_RECORDING, onStopRecording),
];
