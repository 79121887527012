export const AGENT_LOAD_START = 'agent/AGENT_LOAD_START';
export const AGENT_LOAD_SUCCESS = 'agent/AGENT_LOAD_SUCCESS';
export const AGENT_LOGIN_START = 'agent/AGENT_LOGIN_START';
export const AGENT_LOGIN_SUCCESS = 'agent/AGENT_LOGIN_SUCCESS';
export const AGENT_LOGOUT_START = 'agent/AGENT_LOGOUT_START';
export const AGENT_LOGOUT_SUCCESS = 'agent/AGENT_LOGOUT_SUCCESS';
export const AGENT_PAUSE_START = 'agent/AGENT_PAUSE_START';
export const AGENT_PAUSE_SUCCESS = 'agent/AGENT_PAUSE_SUCCESS';
export const AGENT_RESUME_START = 'agent/AGENT_RESUME_START';
export const AGENT_RESUME_SUCCESS = 'agent/AGENT_RESUME_SUCCESS';

export const load = () => ({ type: AGENT_LOAD_START });

export const loadSuccess = agent => ({ type: AGENT_LOAD_SUCCESS, payload: { agent } });

export const login = () => ({ type: AGENT_LOGIN_START });

export const loginSuccess = () => ({ type: AGENT_LOGIN_SUCCESS });

export const logout = () => ({ type: AGENT_LOGOUT_START });

export const logoutSuccess = () => ({ type: AGENT_LOGOUT_SUCCESS });

export const pause = () => ({ type: AGENT_PAUSE_START });

export const pauseSuccess = () => ({ type: AGENT_PAUSE_SUCCESS });

export const resume = () => ({ type: AGENT_RESUME_START });

export const resumeSuccess = () => ({ type: AGENT_RESUME_SUCCESS });
