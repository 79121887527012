export const INTEGRATION_CONFIG_RETRIEVED = 'main/INTEGRATION_CONFIG_RETRIEVED';
export const ON_CLICK_TO_CALL = 'main/ON_CLICK_TO_CALL';
export const ON_CARD_CREATED = 'main/ON_CARD_CREATED';
export const ENABLE_CARD = 'main/DISABLE_CARD';

export const enableCard = () => ({
  type: ENABLE_CARD,
});

export const onConfigRetrieved = config => ({
  type: INTEGRATION_CONFIG_RETRIEVED,
  payload: { config },
});

export const onClickToCall = number => ({
  type: ON_CLICK_TO_CALL,
  payload: { number },
});

export const onCardCreated = id => ({
  type: ON_CARD_CREATED,
  payload: { id },
});
