import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import colors from '../../main/themes/colors';
import CloseButton from '../../main/components/CloseButton';

const Container = styled(Box)`
  position: relative;
  width: calc(80% - 40px);
  height: 80%;
  margin: 30px auto;
  padding: 20px;
  background-color: ${colors.secondGrey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Input = styled(TextField)`
  && {
    width: 100%;
  }
`;

const ValidateButton = styled(Button)`
  && {
    width: 120px;
    margin: 10px auto 0;
    border-radius: 28px;
  }
`;

const NewContactForm = ({ callSession, onAddContact, setModalOpen, t }) => {
  const names = callSession.number !== callSession.displayName ? callSession.displayName?.split(' ') : [] || [];
  const rawFirstName = names[0] || '';
  const rawLastName = names.slice(1).join(' ');

  const [phone, setPhone] = useState(callSession.number || '');
  const [errors, setErrors] = useState({});
  const [firstname, setFirstname] = useState(rawFirstName);
  const [lastname, setLastname] = useState(rawLastName);

  const onValidate = () => {
    const newErrors = {};
    if (!firstname.length) {
      newErrors.firstname = true;
    }
    if (!lastname.length) {
      newErrors.lastname = true;
    }
    if (!phone.length) {
      newErrors.phone = true;
    }

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }

    onAddContact({ firstname, lastname, phone });
  };

  return (
    <Container component="form">
      <CloseButton onClick={() => setModalOpen(false)}>
        <CloseIcon />
      </CloseButton>

      <Typography align="center" variant="h2">{t('addContact')}</Typography>

      <Input error={'firstname' in errors} label={t('firstname')} margin="normal" onChange={e => setFirstname(e.target.value)} value={firstname} />
      <Input error={'lastname' in errors} label={t('lastname')} margin="normal" onChange={e => setLastname(e.target.value)} value={lastname} />
      <Input error={'phone' in errors} label={t('phone')} margin="normal" onChange={e => setPhone(e.target.value)} value={phone} />

      <ValidateButton onClick={onValidate} variant="outlined">
        {t('validateNewContact')}
      </ValidateButton>
    </Container>
  );
};

const mapStateToProps = state => ({
  callSession: state.card.callSession,
});

export default connect(mapStateToProps)(withTranslation('card')(NewContactForm));
