export const SAVE_CARD_REQUEST = 'card/SAVE_CARD_REQUEST';
export const SKIP_CARD = 'card/SKIP_CARD';
export const STORE_CARD_ID = 'card/STORE_CARD_ID';
export const CREATE_OR_UPDATE_CARD_REQUEST = 'card/CREATE_OR_UPDATE_CARD_REQUEST';
export const CREATE_OR_UPDATE_CARD_SUCCESS = 'card/CREATE_OR_UPDATE_CARD_SUCCESS';
export const CARD_SET_VALUE = 'card/CARD_SET_VALUE';
export const ON_MULTIPLE_LINKED_ENTITIES_FOUND = 'card/ON_MULTIPLE_LINKED_ENTITIES_FOUND';
export const DISPLAY_LINKED_ENTITY = 'card/DISPLAY_LINKED_ENTITY';
export const SEARCH_ENTITIES = 'contact/SEARCH_ENTITIES';
export const ON_ENTITIES_FOUND = 'contact/ON_ENTITIES_FOUND';
export const ON_SAVING_CARD = 'contact/ON_SAVING_CARD';
export const ON_SAVED_CARD = 'contact/ON_SAVED_CARD';

export const saveCard = (cardContent, callSession) => ({
  type: SAVE_CARD_REQUEST,
  payload: { cardContent, callSession },
});

export const skipCard = () => ({
  type: SKIP_CARD,
});

export const storeCardId = id => ({
  type: STORE_CARD_ID,
  payload: { id },
});

export const createOrUpdateCard = (cardId, cardContent, callSession) => ({
  type: CREATE_OR_UPDATE_CARD_REQUEST,
  payload: { cardId, cardContent, callSession },
});

export const cardCreatedOrUpdated = () => ({
  type: CREATE_OR_UPDATE_CARD_SUCCESS,
});

export const setValue = (name, value) => ({
  type: CARD_SET_VALUE,
  payload: { name, value },
});

export const onMultipleLinkedEntitiesFound = (linkedEntities, fieldId) => ({
  type: ON_MULTIPLE_LINKED_ENTITIES_FOUND,
  payload: { linkedEntities, fieldId },
});

export const displayLinkedEntity = linkedEntityId => ({
  type: DISPLAY_LINKED_ENTITY,
  payload: { linkedEntityId },
});

export const searchEntities = (query, fieldId) => ({ type: SEARCH_ENTITIES, payload: { query, fieldId } });

export const onEntitiesFound = (entities, fieldId) => ({ type: ON_ENTITIES_FOUND, payload: { entities, fieldId } });

export const onSavingCard = () => ({ type: ON_SAVING_CARD });

export const onSavedCard = () => ({ type: ON_SAVED_CARD });
