/* eslint-disable camelcase */

// @see https://github.com/TinxHQ/wazo-js-common/blob/master/contacts/selectors/contactSelector.js#L121
import { STATE as PROFILE_STATE, LINE_STATE } from '@wazo/sdk/lib/domain/Profile';
import Wazo from '@wazo/sdk/lib/simple';

const PRESENTIAL_STATE_USE_CONNECTED_ENGINE_VERSION = '21.02';

export const PRESENTIAL_STATES = {
  AVAILABLE: 'PRESENTIAL_AVAILABLE',
  AWAY: 'PRESENTIAL_AWAY',
  DND: 'PRESENTIAL_DND',
  BUSY: 'PRESENTIAL_BUSY',
  DISCONNECTED: 'PRESENTIAL_DISCONNECTED',
  NON_WEBRTC: 'PRESENTIAL_NON_WEBRTC',
  NONE: 'PRESENTIAL_NONE',
};

export const PRESENTIAL_COLORS = {
  GREEN: '#88CD78',
  YELLOW: '#ffbb00',
  RED: '#CC0214',
  GRAY: '#ccc',
};

export const getPresentialColor = presentialState => {
  const { AVAILABLE, AWAY, BUSY, DND, NON_WEBRTC, DISCONNECTED, NONE } = PRESENTIAL_STATES;
  const { GREEN, YELLOW, RED, GRAY } = PRESENTIAL_COLORS;

  switch (presentialState) {
    case AVAILABLE:
      return GREEN;
    case AWAY:
      return YELLOW;
    case BUSY:
    case DND:
      return RED;
    case DISCONNECTED:
      return GRAY;
    case NON_WEBRTC:
      return '#fff';
    case NONE:
    default:
      return 'transparent';
  }
};

export const getPresentialStateV2 = (contact, session) => {
  const useConnected = session?.hasEngineVersionGte?.(PRESENTIAL_STATE_USE_CONNECTED_ENGINE_VERSION);

  const { AVAILABLE, AWAY, BUSY, DND, NON_WEBRTC, DISCONNECTED, NONE } = PRESENTIAL_STATES;
  if (!contact || !contact.isIntern()) {
    return NONE;
  }

  const { lineState, mobile, sessions, connected: inboundConnected, state: profileState, lastActivity } = contact;

  const checkState = () => {
    switch (profileState) {
      case PROFILE_STATE.AVAILABLE:
        return AVAILABLE;
      case PROFILE_STATE.AWAY:
      case PROFILE_STATE.UNAVAILABLE:
        return AWAY;
      default:
        return contact.previousPresential || DISCONNECTED;
    }
  };

  if (contact.doNotDisturb) {
    return DND;
  }

  if (lineState === LINE_STATE.PROGRESSING) {
    return contact.previousPresential || BUSY;
  }

  if (lineState === LINE_STATE.TALKING || lineState === LINE_STATE.HOLDING) {
    return BUSY;
  }

  if (lineState === LINE_STATE.AVAILABLE || lineState === LINE_STATE.RINGING) {
    if (!lastActivity) {
      return NON_WEBRTC;
    }

    const connected = useConnected ? inboundConnected : sessions && !!sessions.length;

    if (!connected && !mobile) {
      return NON_WEBRTC;
    }

    return checkState();
  }

  if (mobile) {
    return checkState();
  }

  return contact.previousPresential || DISCONNECTED;
};

export const updateContactWithStatus = (contact, payload) => {
  const {
    state,
    status,
    sessions,
    connected,
    line_state: lineState,
    mobile,
    last_activity,
    do_not_disturb: doNotDisturb,
    lines,
  } = payload;

  return Wazo.domain.Contact.newFrom({
    ...contact,
    personalStatus: status,
    status,
    state,
    lineState,
    mobile,
    sessions,
    connected,
    lastActivity: last_activity,
    doNotDisturb,
    ringing: lineState === 'ringing',
    lines,
  });
};

export const updateContactsWithStatuses = (contacts, statuses) => {
  statuses.forEach(status => {
    const contactIdx = contacts.findIndex(someContact => someContact.uuid === status.uuid);
    if (contactIdx === -1) {
      return;
    }

    contacts[contactIdx] = updateContactWithStatus(contacts[contactIdx], status);
  });

  return contacts;
};
