import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE,
  IS_AUTHENTICATING,
  LOGOUT_SUCCESS,
} from '../actions/userActions';

const initialState = {
  requiredSubscriptionType: 0,
  authenticated: false,
  authenticating: false,
  session: undefined,
  error: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
    case IS_AUTHENTICATING:
      return { ...state, error: false, authenticated: false, authenticating: true };

    case LOGIN_SUCCESS:
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authenticated: true,
        authenticating: false,
        session: action.payload.session,
      };

    case LOGIN_FAILURE:
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        authenticated: false,
        authenticating: false,
        error: action.payload.error,
      };

    case ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE:
      return { ...state, requiredSubscriptionType: action.payload.subscriptionType };

    case LOGOUT_SUCCESS:
      // Don't forget to not overwrite `requiredSubscriptionType` on logout
      return { ...initialState, requiredSubscriptionType: state.requiredSubscriptionType };

    default:
      return state;
  }
}
