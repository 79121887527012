import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '@mui/material/Modal';

import { searchEntities } from '../actions/cardActions';

import NewContactForm from './NewContactForm';

const Container = styled(FormControl)`
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 10px !important;
`;

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`;

export const LINKED_CONTACT_FIELD = 'linkedContact';
export const LINKED_RELATION_FIELD = 'linkedRelation';
export const NEW_CONTACT_VALUE = 'new-contact';

const entitiesToOptions = (entities, suggested = true) => entities.map(entity => ({
  label: entity.wazoLabel,
  id: entity.wazoId,
  suggested,
  ...entity,
}));

const EntitySelection = ({
  allowCreate,
  actions,
  error,
  label,
  name,
  entities,
  t,
  searching,
  onChange,
  searchedEntities,
}) => {
  const [options, setOptions] = useState(entitiesToOptions(entities, true));
  const [modalOpen, setModalOpen] = useState(false);
  const [newContact, setNewContact] = useState(null);
  const [inputValue, setInputValue] = useState('');
  // Avoid sending `undefined` to value or the Autocomplete will be considered as uncontrolled.
  const [value, setValue] = useState(options.length ? options[0] : null);

  if (searchedEntities) {
    options.push(...entitiesToOptions(searchedEntities, false));
  }

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue && newInputValue.length > 2) {
      actions.searchEntities(newInputValue, name);
    }
  };

  const onChangeValue = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const onAddContact = ({ firstname, lastname, phone }) => {
    setModalOpen(false);

    setNewContact({
      firstname,
      lastname,
      phone,
      wazoId: NEW_CONTACT_VALUE,
      label: `${firstname} ${lastname} (Contact)`,
      id: null,
      suggested: true,
    });
  };

  useEffect(() => {
    if (newContact) {
      setValue(newContact);
      onChange(newContact);

      setOptions([...options, newContact]);
    }
  }, [newContact]);

  useEffect(() => {
    const newOptions = entitiesToOptions(entities, true);
    setOptions(newOptions);
    if (!value && newOptions.length) {
      setValue(newOptions[0]);
    }
  }, [entities]);

  return (
    <Container>
      <StyledAutocomplete
        groupBy={(option) => option.suggested ? t('suggested') : t('others')}
        inputValue={inputValue}
        label={label}
        onChange={onChangeValue}
        onInputChange={onInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.Id}>
            {option.label}
          </li>
        )}
        value={value}
      />
      {allowCreate && (
        <IconButton onClick={() => setModalOpen(true)}>
          <AddIcon />
        </IconButton>
      )}
      <Modal onClose={() => setModalOpen(false)} open={modalOpen}>
        <div>
          <NewContactForm onAddContact={onAddContact} setModalOpen={setModalOpen} />
        </div>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => ({
  searchedEntities: state.card.searchedEntities[ownProps.name],
  searching: state.card.searching[ownProps.name],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchEntities }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('card')(EntitySelection)));
