import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './translations';

const defaultLanguage = localStorage.getItem('language') || 'en';

i18n.use(initReactI18next).init({
  resources: translations,
  fallbackLng: defaultLanguage,
  lng: defaultLanguage,
  interpolation: { escapeValue: false },
});

export const getTranslator = namespace => i18n.getFixedT(i18n.language, namespace);

export default i18n;
