export default {
  username: 'Username',
  password: 'Password',
  next: 'Next',
  login: 'Login',
  wrongCredentials: 'Wrong login or password',
  errorOccurred: 'An error occurred',
  authFailure: 'Authentication failure',
  noAuthorization: 'This account doesn\'t have access to the application',
  ldapConnection: 'Log in with LDAP',
  ldapLogin: 'Log in with LDAP',

  userState: 'State',
  userStatus: 'Status',
  available: 'Available',
  doNotDisturb: 'Do not disturb',
  notAvailable: 'Not available',
  addStatus: 'Add a status...',
};
