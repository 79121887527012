import { put, takeEvery } from 'redux-saga/effects';

import { ON_CLICK_TO_CALL } from '../../main/actions/bridgeActions';
import { changeDialerValue } from '../actions/dialerActions';

function* changeNumber({ payload: { number } }) {
  yield put(changeDialerValue(number));
}

export default [takeEvery(ON_CLICK_TO_CALL, changeNumber)];
