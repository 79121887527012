import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getPresentialColor, getPresentialStateV2 } from '../utils/contactState';

const Indicator = styled.div`
  position: absolute;
  z-index: 10;
  left: ${({ left }) => left}px;
  top: ${({ top }) => top}px;
  border-radius: 10000px;
  background-color: ${({ bgColor }) => bgColor};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StateIndicator = ({ session, contact, top = 48, left = 42, size = 11 }) =>
  <Indicator bgColor={getPresentialColor(getPresentialStateV2(contact, session))} left={left} size={size} top={top} />;

const mapStateToProps = state => ({
  session: state.user.session,
});

export default connect(mapStateToProps)(StateIndicator);
