import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';

import colors from '../themes/colors';

const CloseButton = styled(IconButton)`
  position: absolute !important;
  background: ${colors.primary} !important;
  width: 25px;
  height: 25px;
  z-index: 400;
  right: -12px;
  top: -12px;
  
  svg {
    color: #fff;
    font-size: 18px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04) !important;
  }
`;

export default CloseButton;
