import { put, takeEvery, call, select } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';

import { getTranslator } from '../../i18n';
import { INTEGRATION_CONFIG_RETRIEVED } from '../../main/actions/bridgeActions';
import {
  LOGIN_REQUEST,
  AUTHENTICATION_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  logoutSuccess,
  loginFailed,
  loginSuccess,
  authenticationFailed,
  authenticationSuccess,
  setIsAuthenticating,
} from '../actions/userActions';
import history from '../../main/router/history';
import { load as loadAgent } from '../../agent/actions/agentActions';
import { listenToCallEvents } from '../../call/actions/callActions';
import { fetchWazoSource } from '../../contact/sagas/contactsSagas';
import { fetchCallLogs } from '../../callLogs/actions/callLogActions';

const ONE_MONTH = 60 * 60 * 730;
const t = getTranslator('user');
export const integration = process.env.REACT_APP_INTEGRATION || 'index';

Wazo.Auth.init(`wazo-softphone-${integration}`, ONE_MONTH);

export const AUTHORIZATION_NAME = 'application-client-access';

export function* onLogin(session, success, failure, useLdap) {
  try {
    // Check if the user has access to the application
    yield Wazo.Auth.checkAuthorizations(session, AUTHORIZATION_NAME);

    localStorage.setItem('userToken', session.token);
    localStorage.setItem('useLdap', String(useLdap));

    yield put(success(session));
    yield fetchWazoSource();
    yield put(fetchCallLogs());
    yield put(loadAgent());
    yield put(listenToCallEvents());
    // fetchOwnContact is called right after Wazo.Phone.connect()

    yield call(history.push, '/dialer');
  } catch (e) {
    yield put(failure(e instanceof Wazo.InvalidSubscription ? t('noAuthorization') : t('errorOccurred'), e));
  }
}

function* loginUser({ payload: { username, password, useLdap } }) {
  const {
    main: { integrationConfig },
  } = yield select();
  const tenantId = integrationConfig?.tenantId;
  const backend = useLdap ? Wazo.Auth.BACKEND_LDAP : Wazo.Auth.BACKEND_WAZO;

  try {
    const session = yield Wazo.Auth.logIn(username, password, backend, useLdap ? tenantId : null);

    yield onLogin(session, loginSuccess, loginFailed, useLdap);
  } catch (e) {
    yield put(loginFailed(e instanceof Wazo.InvalidSubscription ? t('noAuthorization') : t('wrongCredentials'), e));
  }
}

function* authenticateUser() {
  const state = yield select();
  const token = localStorage.getItem('userToken');
  if (!token || state.user.authenticated) {
    return;
  }

  try {
    put(setIsAuthenticating());
    const session = yield Wazo.Auth.validateToken(token);

    yield onLogin(session, authenticationSuccess, authenticationFailed);
  } catch (e) {
    yield put(authenticationFailed(t('authFailure'), e));
  }
}

export function* onLogout() {
  yield put(logoutSuccess());
}

function* logout() {
  localStorage.clear();

  Wazo.Phone.disconnect();
  yield Wazo.Auth.logout();

  yield call(history.push, '/');
}

export default [
  takeEvery(LOGIN_REQUEST, loginUser),
  takeEvery(AUTHENTICATION_REQUEST, authenticateUser),
  takeEvery(INTEGRATION_CONFIG_RETRIEVED, authenticateUser),
  takeEvery(LOGOUT_REQUEST, onLogout),
  takeEvery(LOGOUT_SUCCESS, logout),
];
