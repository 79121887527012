class BridgeConfig {

  constructor() {
    this.config = {};

    this.restoreConfig();
  }

  getValue = key => this.config[key];

  setValue = (key, val) => {
    this.config[key] = val;

    this.storeConfig();
  };

  storeConfig = () => {
    localStorage.setItem('bridgeConfig', JSON.stringify(this.config));
  };

  restoreConfig = () => {
    const config = localStorage.getItem('bridgeConfig');
    if (!config) {
      return;
    }

    this.config = JSON.parse(config);
  };

}

export default new BridgeConfig();
