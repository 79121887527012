import { takeEvery, call, put, select } from 'redux-saga/effects';

import { SAVE_CARD_REQUEST, SKIP_CARD, storeCardId, createOrUpdateCard } from '../actions/cardActions';
import { ON_CARD_CREATED } from '../../main/actions/bridgeActions';
import history from '../../main/router/history';

function* saveCard({ payload: { cardContent, callSession } }) {
  const {
    card: { cardId },
  } = yield select();

  yield put(createOrUpdateCard(cardId, cardContent, callSession));
  yield call(history.push, '/dialer');
}

function* skipCard() {
  yield call(history.push, '/dialer');
}

function* onCardCreated({ payload: { id } }) {
  yield put(storeCardId(id));
}

export default [
  takeEvery(SKIP_CARD, skipCard),
  takeEvery(SAVE_CARD_REQUEST, saveCard),
  takeEvery(ON_CARD_CREATED, onCardCreated),
];
