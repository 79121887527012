import React, { useEffect } from 'react';
import styled from 'styled-components';
import Wazo from '@wazo/sdk/lib/simple';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
`;

const MainContainer = ({ children }) => {
  const hangupAllCalls = () => {
    Object.values(Wazo.Phone.phone.callSessions).forEach(callSession => {
      Wazo.Phone.hangup(callSession);
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', event => {
      hangupAllCalls();
      delete event.returnValue;
      return false;
    });
    window.addEventListener('unload', () => {
      hangupAllCalls();
    });
  }, []);

  return (
    <Container>
      {children}
    </Container>
  );
};

export default MainContainer;
