// @see https://github.com/TinxHQ/wazo-js-common/blob/master/contacts/domain/Initials.js
export default class Initials {

  static parse(name, firstAndLastNames = false) {
    if (!name) {
      return '#';
    }

    if (!firstAndLastNames) {
      return name.charAt(0).toLocaleUpperCase();
    }

    const getInitials = (firstName, lastName) => {
      const firstLetter = firstName.charAt(0);
      const secondLetter = lastName.charAt(0);
      return firstLetter.toUpperCase() + secondLetter.toUpperCase();
    };

    const composedNames = name.split('-');
    if (composedNames.length > 1) {
      return getInitials(composedNames[0], composedNames[1]);
    }

    const names = name.split(' ');
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();
    }

    return getInitials(names[0], names[names.length - 1]);
  }

  static parseFromContact(contact, firstAndLastNames = false) {
    if (!contact) {
      return '#';
    }

    if (!contact.name && contact.emails.length > 0) {
      return '@';
    }

    return this.parse(contact.name, firstAndLastNames);
  }

}
