import React from 'react';
import styled from 'styled-components';
import Search from '@mui/icons-material/Search';
import Backspace from '@mui/icons-material/Backspace';
import { IconButton, Zoom } from '@mui/material';
import { withTranslation } from 'react-i18next';

import colors from '../../main/themes/colors';

const Container = styled.div`
  flex: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 16px;
  margin-bottom: 20px;
  background: ${colors.secondGrey};
`;

const Input = styled.input`
  flex: 1;
  text-align: center;
  font-size: 32px;
  border: 0 none;
  outline: none;
  background: transparent;
  width: calc(100% - 36px);

  padding-left: 24px;

  &::-webkit-input-placeholder {
    font-size: 26px;
  }
`;

const Eraser = styled(IconButton)`
  & svg {
    width: .8em;
    height: .8em;
    fill: ${colors.secondary};
  }
`;

const SearchButton = styled(IconButton)`
  margin-left: 30px !important;

  &.Mui-disabled {
    opacity: .3;
  }

  & svg {
    width: .8em;
    height: .8em;
    fill: ${colors.secondary};
  }
`;

const Keypad = ({ call, number, onChange, onSearch, placeholder, searching, t }) => (
  <Container className="keypad-number-container">
    <Input
      className="keypad-number"
      onChange={e => onChange(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          if (number.match(/^[A-zÀ-ú\s-]+$/)) {
            onSearch();
          } else {
            call(number);
          }
        }
      }}
      placeholder={placeholder || t('searchInWazo')}
      type="text"
      value={number}
    />

    <Zoom in={number.length > 0}>
      <div>
        <Eraser className="keypad-eraser" onClick={() => onChange(number.slice(0, -1))} size="small">
          <Backspace />
        </Eraser>
        {number.length > 0 && onSearch && (
          <SearchButton className="keypad-search" disabled={searching} onClick={onSearch} size="small">
            <Search />
          </SearchButton>
        )}
      </div>
    </Zoom>

  </Container>
);

export default React.memo(withTranslation('call')(Keypad));
