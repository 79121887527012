import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import { searchContact } from '../../contact/actions/contactActions';
import KeypadNumber from '../components/KeypadNumber';
import Keys from '../components/Keys';
import SearchResults from '../../contact/components/SearchResults';
import colors from '../../main/themes/colors';
import CloseButton from '../../main/components/CloseButton';

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ transfer }) => transfer ? '-10px' : 0};
`;

const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ResultsContainer = styled.div`
  position: absolute;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 200px);
  background: ${colors.secondGrey};
  z-index: 200;
  border-radius: 10px;
  
  & .MuiListItem-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  & .MuiIconButton-edge {
    margin-right: 0 !important;
  }
`;

const Keypad = ({
  transfer,
  actions,
  searchResults,
  searching,
  call,
  number,
  onChange,
  placeholder,
  sendDTMF,
  SearchResultsButtons,
  getDisplaySearchMethod,
}) => {
  const [displaySearch, setDisplaySearch] = useState(false);

  const onChangeNumber = value => {
    onChange(value);
    if (displaySearch) {
      actions.searchContact(value);
    }
  };

  const onSearch = () => {
    actions.searchContact(number);
    setDisplaySearch(true);
  };

  useEffect(() => {
    if (getDisplaySearchMethod) {
      getDisplaySearchMethod(setDisplaySearch);
    }
  }, []);

  return (
    <Container transfer={transfer}>
      <KeypadNumber
        call={call}
        number={number}
        onChange={onChangeNumber}
        onSearch={onSearch}
        placeholder={placeholder}
        searching={searching}
      />

      {displaySearch && (
        <ResultsContainer>
          <CloseButton onClick={() => setDisplaySearch(false)}>
            <CloseIcon />
          </CloseButton>
          {searching ? <Progress><CircularProgress size={50} /></Progress> : (
            <SearchResults
              full
              results={searchResults}
              SearchResultsButtons={SearchResultsButtons}
            />
          )}
        </ResultsContainer>
      )}

      <Keys number={number} onChange={onChangeNumber} sendDTMF={sendDTMF} />
    </Container>
  );
};

const mapStateToProps = state => ({
  searchResults: state.contact.searchResults,
  searching: state.contact.searching,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    searchContact,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Keypad));
