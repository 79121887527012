import React from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import Reply from '@mui/icons-material/Reply';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import colors from '../../main/themes/colors';

const StyledCall = styled(Fab)`
  && {
    color: ${colors.white};
    background-color: ${colors.secondary};
    width: 60px;
    height: 60px;

    &:hover {
      background-color: ${colors.hovered};
    }
  }
`;

export const ReturnToCard = withTranslation('card')(({ t }) => (
  <Link to="/call">
    <Tooltip placement="top" title={t('returnToCard')}>
      <StyledCall call={1}>
        <Reply />
      </StyledCall>
    </Tooltip>
  </Link>
));
