import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography/Typography';
import { withTranslation } from 'react-i18next';

import { login, setIsAuthenticating } from '../actions/userActions';
import colors from '../../main/themes/colors';
import logo from '../../main/assets/images/logo.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  padding: 25px 20px;

    
  @media(min-width: 700px) {
    #logo {
      margin: 20px auto 7vh;
    }

    form {
      width: 80vw;
    }
  }
  @media(min-width: 1200px) {
    #logo {
      width: 10vw;
    }
  }
`;

const Logo = styled.img`
  display: block;
  width: 110px;
  margin: 20px auto 50px;
`;

const Form = styled.form``;

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const StyledError = styled(Typography)`
  && {
    text-align: center;
    margin: 20px 0;
    color: ${colors.error};
  }
`;

const LoginButton = styled(Button)`
  && {
    display: block;
    width: ${({ $useLdap }) => $useLdap ? 250 : 150}px;
    margin: 0 auto;
    border-radius: 28px;
    padding-top: ${({ authenticating }) => (authenticating ? '10px' : '6px')};
    height: ${({ authenticating }) => (authenticating ? '40px' : 'auto')};
  }
`;

const Check = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  margin: 20px;
`;

export const Login = ({ authenticating, error, actions, t }) => {
  const [values, setValues] = useState({ username: '', password: '' });
  const [useLdap, setUseLdap] = useState(localStorage.getItem('useLdap') === 'true');

  const setValue = (name, value) => setValues({ ...values, [name]: value });

  const allFieldsArePresent = () => !!values.username && !!values.password;

  const performLogin = e => {
    e.preventDefault();

    actions.login(values.username, values.password, useLdap);
  };

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      // Display the authenticating loaded as soon as possible
      actions.setIsAuthenticating();
    }
  }, []);

  const toggleUseLdap = () => setUseLdap(!useLdap);

  return (
    <Container>
      <Content>

        <Logo alt="Wazo" id="logo" src={logo} />

        <Form onSubmit={performLogin}>
          <StyledTextField
            autoComplete="username"
            disabled={authenticating}
            error={!!error}
            id="username"
            label={t('username')}
            onChange={event => setValue('username', event.target.value)}
            type="text"
            value={values.email}
            variant="outlined"
          />

          <StyledTextField
            autoComplete="user-password"
            disabled={authenticating}
            error={!!error}
            id="password"
            label={t('password')}
            onChange={event => setValue('password', event.target.value)}
            type="password"
            value={values.password}
            variant="outlined"
          />

          <Check onClick={toggleUseLdap}>
            <Switch checked={useLdap} color="primary" onChange={toggleUseLdap} />
            <Typography color="textPrimary">{t('ldapConnection')}</Typography>
          </Check>

          {!!error && <StyledError component="p">{error}</StyledError>}

          <LoginButton
            $useLdap={useLdap}
            authenticating={authenticating ? 1 : 0}
            disabled={authenticating || !allFieldsArePresent()}
            id="loginButton"
            type="submit"
            variant="contained"
          >
            {authenticating ? <CircularProgress size={20} /> : (useLdap ? t('ldapLogin') : t('login'))}
          </LoginButton>
        </Form>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => ({
  authenticating: state.user.authenticating,
  error: state.user.error,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ login, setIsAuthenticating }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('user')(Login)));
