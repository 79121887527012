import { List as MuiList } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import colors from '../themes/colors';

const Container = styled.div`
  margin-bottom: 24px;

  .MuiListItem-gutters {
    padding-left: 42px;
    padding-right: 42px;
  }

  .MuiListItem-root {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.divider};
    &:last-child {
      border-bottom: none;
    }
  }

  .MuiAvatar-colorDefault {
    background: ${colors.secondary};
  }
`;

const List = ({ children }) => (
  <Container>
    <MuiList>
      {children}
    </MuiList>
  </Container>
);

export default List;
