export const FETCH_WAZO_SOURCE_SUCCESS = 'contact/FETCH_WAZO_SOURCE_SUCCESS';

export const FETCH_OWN_CONTACT_START = 'contact/FETCH_OWN_CONTACT_START';
export const FETCH_OWN_CONTACT_SUCCESS = 'contact/FETCH_OWN_CONTACT_SUCCESS';

export const CONTACT_SEARCH_START = 'contact/CONTACT_SEARCH_START';
export const CONTACT_SEARCH_SUCCESS = 'contact/CONTACT_SEARCH_SUCCESS';
export const CONTACT_SEARCH_FAILURE = 'contact/CONTACT_SEARCH_FAILURE';

export const CONTACT_STATE_UPDATE_START = 'contact/CONTACT_STATE_UPDATE_START';

export const CONTACT_STATUS_UPDATE_START = 'contact/CONTACT_STATUS_UPDATE_START';
export const CONTACT_STATUS_UPDATE_SUCCESS = 'contact/CONTACT_STATUS_UPDATE_SUCCESS';

export const CONTACT_UPDATE_DND = 'contact/CONTACT_UPDATE_DND';

export const CALL_LOGS_CONTACTS_FETCHED = 'contact/CALL_LOGS_CONTACTS_FETCHED';

export const wazoSourceFetched = source => ({ type: FETCH_WAZO_SOURCE_SUCCESS, payload: { source } });

export const fetchOwnContact = () => ({ type: FETCH_OWN_CONTACT_START });

export const ownContactFetched = contact => ({ type: FETCH_OWN_CONTACT_SUCCESS, payload: { contact } });

export const searchContact = query => ({ type: CONTACT_SEARCH_START, payload: { query } });

export const searchContactSuccess = contacts => ({ type: CONTACT_SEARCH_SUCCESS, payload: { contacts } });

export const searchContactFailure = error => ({ type: CONTACT_SEARCH_FAILURE, payload: { error } });

export const updateUserState = state => ({ type: CONTACT_STATE_UPDATE_START, payload: { state } });

export const updateUserStatus = (state, status) => ({ type: CONTACT_STATUS_UPDATE_START, payload: { state, status } });

export const userStatusUpdated = () => ({ type: CONTACT_STATUS_UPDATE_SUCCESS });

export const sendDoNotDisturb = value => ({ type: CONTACT_UPDATE_DND, payload: { value } });

export const onCallLogContactsFetched = contacts => ({
  type: CALL_LOGS_CONTACTS_FETCHED,
  payload: { contacts },
});
