import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import { InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { withTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { STATE as PROFILE_STATE } from '@wazo/sdk/lib/domain/Profile';

import { updateUserState, sendDoNotDisturb, updateUserStatus } from '../../contact/actions/contactActions';

const Container = styled.div`
  padding-top: 30px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
  }
`;

const StyledForm = styled(FormControl)`
  && {
    display: block;
    width: 80%;
    margin: 0 auto !important;
    & > div {
      width: 100%;
    }
  }
`;

const StatusInput = styled(OutlinedInput)`
  width: 100%;
  margin-top: 50px !important;
  input {
    background: #fff;
    border-radius: 4px;
  }
  
  legend {
    height: 15px;
  }
  
  legend>span {
    opacity: 1;
  }
`;

const UserState = ({ t, actions, ownContact, updating }) => {
  const [status, setStatus] = useState(ownContact?.personalStatus || '');

  const handleStateChange = event => {
    const newState = event.target.value;
    if (newState === PROFILE_STATE.UNAVAILABLE) {
      actions.sendDoNotDisturb(true);
    } else {
      actions.sendDoNotDisturb(false);
      actions.updateUserState(newState);
    }
  };

  const saveStatus = () => {
    actions.updateUserStatus(ownContact.state, status);
  };

  useEffect(() => {
    if (ownContact?.personalStatus !== status) {
      setStatus(ownContact.personalStatus);
    }
  }, [ownContact?.personalStatus]);

  return (
    <Container className="settings-container">
      <Title className="setting-title" component="h1">
        {t('userState')}
      </Title>

      <StyledForm variant="outlined">
        <Select
          onChange={handleStateChange}
          value={ownContact.doNotDisturb ? PROFILE_STATE.UNAVAILABLE : ownContact.state}
        >
          <MenuItem value={PROFILE_STATE.AVAILABLE}>{t('available')}</MenuItem>
          <MenuItem value={PROFILE_STATE.AWAY}>{t('notAvailable')}</MenuItem>
          <MenuItem value={PROFILE_STATE.UNAVAILABLE}>{t('doNotDisturb')}</MenuItem>
        </Select>
      </StyledForm>

      <StyledForm variant="outlined">
        <StatusInput
          endAdornment={
            <InputAdornment position="end">
              {updating ? <CircularProgress size={20} /> : null}
            </InputAdornment>
          }
          inputProps={{
            onBlur: saveStatus,
            id: 'user-status-input',
            maxLength: 25,
          }}
          label={t('userStatus')}
          onChange={e => setStatus(e.target.value)}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              saveStatus();
            }
          }}
          placeholder={t('addStatus')}
          value={status}
        />
      </StyledForm>
    </Container>
  );
};

const mapStateToProps = state => ({
  ownContact: state.contact.ownContact,
  updating: state.contact.updatingOwnContact,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateUserState, sendDoNotDisturb, updateUserStatus }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('user')(UserState)));
