/* eslint-disable react-hooks/exhaustive-deps */

// @see stackoverflow.com/questions/48048957/react-long-press-event
import { useState, useEffect, useCallback } from 'react';

export default function useLongPress(longCb = () => {}, shortCb = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId;

    if (startLongPress) {
      shortCb();
      timerId = setTimeout(longCb, ms);
    } else {
      timerId && clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startLongPress]);

  const start = useCallback(() => {
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
}
