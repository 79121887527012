import {
  CONTACT_SEARCH_START,
  CONTACT_SEARCH_SUCCESS,
  CONTACT_SEARCH_FAILURE,
  FETCH_OWN_CONTACT_SUCCESS,
  FETCH_WAZO_SOURCE_SUCCESS,
  CALL_LOGS_CONTACTS_FETCHED,
  CONTACT_STATUS_UPDATE_START,
  CONTACT_STATUS_UPDATE_SUCCESS,
} from '../actions/contactActions';

const initialState = {
  updatedAt: null,
  ownContact: null,
  updatingOwnContact: false,
  wazoSource: null,
  searchResults: [],
  searchQuery: '',
  searchError: false,
  searchDone: false,
  searching: false,
  callLogContacts: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_WAZO_SOURCE_SUCCESS:
      return { ...state, wazoSource: action.payload.source };

    case FETCH_OWN_CONTACT_SUCCESS:
      return { ...state, ownContact: action.payload.contact };

    case CONTACT_STATUS_UPDATE_START:
      return { ...state, updatingOwnContact: true };

    case CONTACT_STATUS_UPDATE_SUCCESS:
      return { ...state, updatingOwnContact: false };

    case CONTACT_SEARCH_START:
      return { ...state, searching: true, searchError: false, searchQuery: action.payload.query };

    case CALL_LOGS_CONTACTS_FETCHED:
      return { ...state, callLogContacts: action.payload.contacts, updatedAt: new Date() };

    case CONTACT_SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        searchError: false,
        searchResults: action.payload.contacts,
        updatedAt: new Date(),
      };

    case CONTACT_SEARCH_FAILURE:
      return { ...state, searching: false, searchError: true };

    default:
      return state;
  }
}
