import { combineReducers } from 'redux';

import user from '../../user/reducers/userReducer';
import dialer from '../../dialer/reducers/dialerReducer';
import call from '../../call/reducers/callReducer';
import card from '../../card/reducers/cardReducer';
import agent from '../../agent/reducers/agentReducer';
import contact from '../../contact/reducers/contactReducer';
import callLogs from '../../callLogs/reducers/callLogReducer';

import main from './mainReducer';

export const initialState = {
  main,
  user,
  dialer,
  call,
  card,
  agent,
  contact,
  callLogs,
};

const appReducer = combineReducers(initialState);

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
