import { eventChannel } from 'redux-saga';
import { put, call, take, takeEvery, select } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';

import bridgeConfig from '../bridge/config';
import {
  LOGIN_SUCCESS,
  AUTHENTICATION_SUCCESS,
  LOGOUT_SUCCESS,
  changeRequiredSubscriptionType,
} from '../../user/actions/userActions';
import { onConfigRetrieved, onClickToCall, onCardCreated, enableCard } from '../actions/bridgeActions';
import {
  CALL_ENDED,
  CALL_HOLD,
  CALL_MADE,
  CALL_MUTE,
  CALL_REMOTLY_ACCEPTED,
  CALL_RESUME,
  CALL_SEND_DTMF,
  CALL_UNMUTE,
  CANCEL_INDIRECT_TRANSFER,
  CONFIRM_INDIRECT_TRANSFER,
  CREATE_INDIRECT_TRANSFER,
  DIRECT_TRANSFER,
  INDIRECT_TRANSFER_CALL_MADE,
  ON_INDIRECT_TRANSFER_DONE,
  ON_INVITE,
  START_RECORDING,
  STOP_RECORDING,
} from '../../call/actions/callActions';
import {
  CREATE_OR_UPDATE_CARD_REQUEST,
  DISPLAY_LINKED_ENTITY,
  SEARCH_ENTITIES,
  cardCreatedOrUpdated,
  onMultipleLinkedEntitiesFound,
  setValue as setCardValue,
  onEntitiesFound,
  onSavingCard,
  onSavedCard,
  SKIP_CARD,
  SAVE_CARD_REQUEST,
} from '../../card/actions/cardActions';
import i18n from '../../i18n';
import { displayError, UPDATE_LANGUAGE, updateLanguage } from '../actions/mainActions';
import { sendMessage, sendParentMessage } from '../utils/integration';
import { CONTACT_SEARCH_START } from '../../contact/actions/contactActions';
import {
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGOUT_SUCCESS,
  AGENT_PAUSE_SUCCESS,
  AGENT_RESUME_SUCCESS,
} from '../../agent/actions/agentActions';

export const DISPLAY_BOTH_ENTITY_SELECTION = 'both';

// /!\ Beware to not use these constants in sagas. They are used to communicate with the integration (salesforce, ...)
export const BRIDGE_CONFIG_RETRIEVED = 'bridge/CONFIG_RETRIEVED';
export const BRIDGE_AUTHENTICATED = 'bridge/AUTHENTICATED';
export const BRIDGE_LOGGED_OUT = 'bridge/LOGGED_OUT';
export const BRIDGE_CREATE_OR_UPDATE_CARD = 'bridge/BRIDGE_CREATE_OR_UPDATE_CARD';
export const BRIDGE_ON_CLICK_TO_CALL = 'bridge/ON_CLICK_TO_CALL';
export const BRIDGE_CALL_ENDED = 'bridge/CALL_ENDED';
export const BRIDGE_CALL_INCOMING = 'bridge/CALL_INCOMING';
export const BRIDGE_CALL_OUTGOING = 'bridge/CALL_OUTGOING';
export const BRIDGE_CARD_CREATED = 'bridge/LOG_CREATED';
export const BRIDGE_ENABLE_CARD = 'bridge/ENABLE_CARD';
export const BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND = 'bridge/BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND';
export const BRIDGE_SET_CARD_CONTENT = 'bridge/BRIDGE_SET_CARD_CONTENT';
export const BRIDGE_CHANGE_LANGUAGE = 'bridge/CHANGE_LANGUAGE';
export const BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE = 'bridge/CHANGE_REQUIRED_SUBSCRIPTION_TYPE';
export const BRIDGE_DISPLAY_LINKED_ENTITY = 'bridge/DISPLAY_LINKED_ENTITY';
export const BRIDGE_DISPLAY_ERROR = 'bridge/BRIDGE_DISPLAY_ERROR';
export const BRIDGE_SEARCH_ENTITIES = 'bridge/BRIDGE_SEARCH_ENTITIES';
export const BRIDGE_FOUND_ENTITIES = 'bridge/BRIDGE_FOUND_ENTITIES';
export const BRIDGE_SAVING_CARD = 'bridge/BRIDGE_SAVING_CARD';
export const BRIDGE_SAVED_CARD = 'bridge/BRIDGE_SAVED_CARD';
export const BRIDGE_WAZO_CONTACT_SEARCH = 'bridge/BRIDGE_WAZO_CONTACT_SEARCH';
export const BRIDGE_ON_AGENT_LOGGED_IN = 'bridge/BRIDGE_ON_AGENT_LOGGED_IN';
export const BRIDGE_ON_AGENT_LOGGED_OUT = 'bridge/BRIDGE_ON_AGENT_LOGGED_OUT';
export const BRIDGE_ON_AGENT_PAUSED = 'bridge/BRIDGE_ON_AGENT_PAUSED';
export const BRIDGE_ON_AGENT_RESUMED = 'bridge/BRIDGE_ON_AGENT_RESUMED';
export const BRIDGE_ON_LANGUAGE_CHANGED = 'bridge/BRIDGE_ON_LANGUAGE_CHANGED';
export const BRIDGE_ON_CALL_HELD = 'bridge/BRIDGE_ON_CALL_HELD';
export const BRIDGE_ON_CALL_RESUMED = 'bridge/BRIDGE_ON_CALL_RESUMED';
export const BRIDGE_ON_CALL_MUTED = 'bridge/BRIDGE_ON_CALL_MUTED';
export const BRIDGE_ON_CALL_UN_MUTED = 'bridge/BRIDGE_ON_CALL_UN_MUTED';
export const BRIDGE_ON_DTMF = 'bridge/BRIDGE_ON_DTMF';
export const BRIDGE_ON_DIRECT_TRANSFER = 'bridge/BRIDGE_ON_DIRECT_TRANSFER';
export const BRIDGE_ON_CREATE_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CREATE_INDIRECT_TRANSFER';
export const BRIDGE_ON_CANCEL_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CANCEL_INDIRECT_TRANSFER';
export const BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER';
export const BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE = 'bridge/BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE';
export const BRIDGE_ON_INDIRECT_TRANSFER_DONE = 'bridge/BRIDGE_ON_INDIRECT_TRANSFER_DONE';
export const BRIDGE_ON_START_RECORDING = 'bridge/BRIDGE_ON_START_RECORDING';
export const BRIDGE_ON_STOP_RECORDING = 'bridge/BRIDGE_ON_STOP_RECORDING';
export const BRIDGE_ENABLE_CLICK_TO_CALL = 'bridge/BRIDGE_ENABLE_CLICK_TO_CALL';
export const BRIDGE_DISABLE_CLICK_TO_CALL = 'bridge/BRIDGE_DISABLE_CLICK_TO_CALL';
export const BRIDGE_ON_CALL_ESTABLISHED = 'bridge/BRIDGE_ON_CALL_ESTABLISHED';

export const SDK_CLICK_TO_CALL = 'sdk/CLICK_TO_CALL';
export const SDK_ON_CALL_MADE = 'sdk/SDK_ON_CALL_MADE';
export const SDK_CALL_ENDED = 'sdk/ON_CALL_ENDED';
export const SDK_CALL_INCOMING = 'sdk/SDK_CALL_INCOMING';
export const SDK_AUTHENTICATED = 'sdk/SDK_AUTHENTICATED';

const events = {
  [BRIDGE_CONFIG_RETRIEVED]: function*(event) {
    const { data: { config } } = event;
    const { server, port } = config;

    const stackHostname = `${server}${port && +port !== 443 ? `:${port}` : ''}`;
    Wazo.Auth.setHost(stackHostname);

    bridgeConfig.setValue('server', stackHostname);
    yield put(onConfigRetrieved(config));
    if (config.language) {
      yield put(updateLanguage(config.language));
    }
  },
  [BRIDGE_ON_CLICK_TO_CALL]: function*(event) {
    yield put(onClickToCall(event.data.number));
  },
  [BRIDGE_CARD_CREATED]: function*(event) {
    yield put(onCardCreated(event.data.id));
  },
  [BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND]: function*(event) {
    const { linkedEntities, fieldId } = event.data;
    yield put(onMultipleLinkedEntitiesFound(linkedEntities, fieldId));
  },
  [BRIDGE_FOUND_ENTITIES]: function*(event) {
    const { entities, fieldId } = event.data;
    yield put(onEntitiesFound(entities, fieldId));
  },
  [BRIDGE_SAVING_CARD]: function*() {
    yield put(onSavingCard());
  },
  [BRIDGE_SAVED_CARD]: function*() {
    yield put(onSavedCard());
  },
  [BRIDGE_SET_CARD_CONTENT]: function*(event) {
    yield put(setCardValue(event.data.field, event.data.value));
  },
  [BRIDGE_ENABLE_CARD]: function*() {
    yield put(enableCard());
  },
  [BRIDGE_DISPLAY_ERROR]: function*(event) {
    yield put(displayError(event.data.error));
  },
  [BRIDGE_CHANGE_LANGUAGE]: event => {
    i18n.changeLanguage(event.data.language.toLowerCase());
  },
  [BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE]: function*(event) {
    const { subscriptionType } = event.data;
    Wazo.Auth.minSubscriptionType = subscriptionType - 1;

    yield put(changeRequiredSubscriptionType(subscriptionType));
  },
};

function* bindIntegrationEvents() {
  const createChannel = () =>
    eventChannel(emit => {
      window.addEventListener('message', emit);

      return () => {};
    });

  const channel = yield call(createChannel);

  while (true) {
    const payload = yield take(channel);

    if (payload?.data?.type in events) {
      yield events[payload.data.type](payload);
    }
  }
}

// Have to clone callSession to avoid issue: `this._onAccepted(e) could not be cloned`
const cloneCallSession = callSession => ({
  answered: callSession.answered,
  sipCallId: callSession.sipCallId,
  number: callSession.number,
  displayName: callSession.displayName,
  creationTime: callSession.creationTime,
  answerTime: callSession.answerTime,
  endTime: callSession.endTime,
});

function* createOrUpdateCard({ payload: { cardId, cardContent, callSession } }) {
  const {
    call: { direction },
    user: { session },
  } = yield select();
  const userExtension = session.primaryLine().extensions[0].exten;

  cardContent.cardId = cardId;

  sendMessage(BRIDGE_CREATE_OR_UPDATE_CARD, {
    callSession: cloneCallSession(callSession),
    userExtension,
    direction,
    content: cardContent,
  }, true);
  yield put(cardCreatedOrUpdated());
}

function* onCallEnded() {
  const {
    call: { direction, currentCallSession },
    user: { session },
    card,
  } = yield select();

  const userExtension = session.primaryLine().extensions[0].exten;

  // Have to clone callSession to avoid issue: `this._onAccepted(session) could not be cloned`
  const callSession = cloneCallSession(currentCallSession);

  // Enable click to call for non answered calls
  if (!callSession.answered) {
    sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);
  }

  const { content } = card;

  sendMessage(BRIDGE_CALL_ENDED, { direction, callSession, userExtension, content });
  sendParentMessage(SDK_CALL_ENDED, { direction, callSession, userExtension, content });
}

const onAuthentication = ({ payload: { session } }) => {
  sendMessage(BRIDGE_AUTHENTICATED, { session });
  sendParentMessage(SDK_AUTHENTICATED, { session });
};

const onLogout = () => {
  sendMessage(BRIDGE_LOGGED_OUT);
};

const onInvite = ({ payload: { callSession } }) => {
  sendMessage(BRIDGE_CALL_INCOMING, { callSession: cloneCallSession(callSession) });

  sendMessage(BRIDGE_DISABLE_CLICK_TO_CALL);

  sendParentMessage(SDK_CALL_INCOMING, { callSession: cloneCallSession(callSession) });
};

function* callRemotelyAccepted() {
  const {
    call: { currentCallSession },
  } = yield select();

  sendMessage(BRIDGE_ON_CALL_ESTABLISHED, { callSession: cloneCallSession(currentCallSession) });
  sendParentMessage(BRIDGE_ON_CALL_ESTABLISHED, { callSession: cloneCallSession(currentCallSession) });
}

const onCallMade = ({ payload: { callSession } }) => {
  sendMessage(BRIDGE_CALL_OUTGOING, { callSession: cloneCallSession(callSession) });

  sendMessage(BRIDGE_DISABLE_CLICK_TO_CALL);

  sendParentMessage(SDK_ON_CALL_MADE, { callSession: cloneCallSession(callSession) });
};

const displayLinkedEntity = ({ payload: { linkedEntityId } }) =>
  sendMessage(BRIDGE_DISPLAY_LINKED_ENTITY, { linkedEntityId });

const onSearchEntities = ({ payload: { query, fieldId } }) => sendMessage(BRIDGE_SEARCH_ENTITIES, { query, fieldId });

const onWazoContactSearch = ({ payload: { query } }) => sendMessage(BRIDGE_WAZO_CONTACT_SEARCH, { query });

const onAgentLoggedIn = () => sendMessage(BRIDGE_ON_AGENT_LOGGED_IN);
const onAgentLoggedOut = () => sendMessage(BRIDGE_ON_AGENT_LOGGED_OUT);
const onAgentPaused = () => sendMessage(BRIDGE_ON_AGENT_PAUSED);
const onAgentResumed = () => sendMessage(BRIDGE_ON_AGENT_RESUMED);
const onLanguageChanged = ({ payload: { language } }) => sendMessage(BRIDGE_ON_LANGUAGE_CHANGED, { language });

const onCallHeld = () => sendMessage(BRIDGE_ON_CALL_HELD);
const onCallResumed = () => sendMessage(BRIDGE_ON_CALL_RESUMED);
const onCallMuted = () => sendMessage(BRIDGE_ON_CALL_MUTED);
const onCallUnmuted = () => sendMessage(BRIDGE_ON_CALL_UN_MUTED);
const onDTMFSent = ({ payload: { value } }) => sendMessage(BRIDGE_ON_DTMF, { tone: value });
const onDirectTransfer = ({ payload: { number } }) => sendMessage(BRIDGE_ON_DIRECT_TRANSFER, { number });
const onCreateIndirectTransfer = ({ payload: { number } }) =>
  sendMessage(BRIDGE_ON_CREATE_INDIRECT_TRANSFER, { number });
const onCancelIndirectTransfer = () => sendMessage(BRIDGE_ON_CANCEL_INDIRECT_TRANSFER);
const onConfirmIndirectTransfer = () => sendMessage(BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER);
const onIndirectCallMade = ({ payload: { indirectCall } }) =>
  sendMessage(BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE, { call: cloneCallSession(indirectCall) });
const onIndirectTransferDone = () => sendMessage(BRIDGE_ON_INDIRECT_TRANSFER_DONE);
const onStartRecording = () => sendMessage(BRIDGE_ON_START_RECORDING);
const onStopRecording = () => sendMessage(BRIDGE_ON_STOP_RECORDING);

const onSkipCard = () => sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);
const enableClickToCallAfterSavedCard = () => sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);

export default [
  bindIntegrationEvents(),
  takeEvery(LOGIN_SUCCESS, onAuthentication),
  takeEvery(AUTHENTICATION_SUCCESS, onAuthentication),
  takeEvery(LOGOUT_SUCCESS, onLogout),
  takeEvery(CREATE_OR_UPDATE_CARD_REQUEST, createOrUpdateCard),
  takeEvery(CALL_ENDED, onCallEnded),
  takeEvery(ON_INVITE, onInvite),
  takeEvery(CALL_REMOTLY_ACCEPTED, callRemotelyAccepted),
  takeEvery(CALL_MADE, onCallMade),
  takeEvery(DISPLAY_LINKED_ENTITY, displayLinkedEntity),
  takeEvery(SEARCH_ENTITIES, onSearchEntities),
  takeEvery(CONTACT_SEARCH_START, onWazoContactSearch),
  takeEvery(AGENT_LOGIN_SUCCESS, onAgentLoggedIn),
  takeEvery(AGENT_LOGOUT_SUCCESS, onAgentLoggedOut),
  takeEvery(AGENT_PAUSE_SUCCESS, onAgentPaused),
  takeEvery(AGENT_RESUME_SUCCESS, onAgentResumed),
  takeEvery(UPDATE_LANGUAGE, onLanguageChanged),
  takeEvery(CALL_HOLD, onCallHeld),
  takeEvery(CALL_RESUME, onCallResumed),
  takeEvery(CALL_MUTE, onCallMuted),
  takeEvery(CALL_UNMUTE, onCallUnmuted),
  takeEvery(CALL_SEND_DTMF, onDTMFSent),
  takeEvery(DIRECT_TRANSFER, onDirectTransfer),
  takeEvery(CREATE_INDIRECT_TRANSFER, onCreateIndirectTransfer),
  takeEvery(CANCEL_INDIRECT_TRANSFER, onCancelIndirectTransfer),
  takeEvery(CONFIRM_INDIRECT_TRANSFER, onConfirmIndirectTransfer),
  takeEvery(INDIRECT_TRANSFER_CALL_MADE, onIndirectCallMade),
  takeEvery(ON_INDIRECT_TRANSFER_DONE, onIndirectTransferDone),
  takeEvery(START_RECORDING, onStartRecording),
  takeEvery(STOP_RECORDING, onStopRecording),
  takeEvery(SKIP_CARD, onSkipCard),
  takeEvery(SAVE_CARD_REQUEST, enableClickToCallAfterSavedCard),
];
