import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import { ListItem, ListItemText } from '@mui/material';
import { withTranslation } from 'react-i18next';

import List from '../../main/components/List';
import packageJson from '../../../package.json';

const Container = styled.div`
  padding-top: 30px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
  }
`;

const Information = ({ session, integrationConfig, t }) => (
  <Container className="settings-container">
    <Title className="setting-title" component="h1">
      {t('information')}
    </Title>

    <List>
      <ListItem>
        <ListItemText primary={t('currentNumber')} secondary={session.primaryNumber()} />
      </ListItem>
      <ListItem>
        <ListItemText primary={t('communicationServer')} secondary={integrationConfig.server} />
      </ListItem>
      <ListItem>
        <ListItemText primary={t('appVersion')} secondary={packageJson.version} />
      </ListItem>
    </List>
  </Container>
);

const mapStateToProps = state => ({
  integrationConfig: state.main.integrationConfig,
  session: state.user.session,
});

export default connect(
  mapStateToProps,
)(React.memo(withTranslation('settings')(Information)));
