export default {
  title: 'Title',
  note: 'Note',
  skip: 'Skip',
  cancel: 'Cancel',
  save: 'Save',
  entity: 'Linked to',
  relation: 'Related to',
  returnToCard: 'Return to note',
  suggested: 'Suggested',
  others: 'Others',
  firstname: 'Firstname',
  lastname: 'Lastname',
  name: 'Name',
  phone: 'Phone number',
  addContact: 'Add a contact',
  mandatory: 'This field is mandatory',
  validateNewContact: 'Validate',
};
