class SoundPlayer {

  currentSound = null;

  playSound = (audioFile, loop = true) => {
    this.currentSound = new Audio(audioFile);
    this.currentSound.preload = 'none';
    this.currentSound.loop = loop;

    try {
      this.currentSound.play().catch(() => {});
    } catch (e) {
      // Nothing to do
    }
  };

  stop = () => {
    if (!this.currentSound) {
      return;
    }

    this.currentSound.pause();
    this.currentSound = null;
  };

}

export default new SoundPlayer();
