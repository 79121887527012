import Wazo from '@wazo/sdk/lib/simple';
import { put, takeEvery } from 'redux-saga/effects';

import {
  onCallLogsFetched,
  onCallLogsFetchFailed,
  FETCH_CALL_LOGS_START,
} from '../actions/callLogActions';

const CALL_LOGS_LIMIT = 100;

function *onFetchCallLogs() {
  try {
    const callLogs = yield Wazo.api.callLogd.listCallLogs(0, CALL_LOGS_LIMIT);
    yield put(onCallLogsFetched(callLogs));
  } catch (e) {
    put(onCallLogsFetchFailed());
  }
}

export default [
  takeEvery(FETCH_CALL_LOGS_START, onFetchCallLogs),
];
