export const DISPLAY_ERROR = 'main/DISPLAY_ERROR';
export const HIDE_ERROR = 'main/HIDE_ERROR';
export const UPDATE_LANGUAGE = 'main/UPDATE_LANGUAGE';

export const updateLanguage = language => ({
  type: UPDATE_LANGUAGE,
  payload: { language },
});

export const displayError = error => ({
  type: DISPLAY_ERROR,
  error,
});

export const hideError = () => ({
  type: HIDE_ERROR,
});
