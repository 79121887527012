import { eventChannel } from 'redux-saga';
import { call, take } from 'redux-saga/effects';

export function* bindEvents(events, emitter) {
  const createChannel = () =>
    eventChannel(emit => {
      Object.keys(events).forEach(eventName =>
        emitter.on(eventName, payload => {
          let parsedPayload = payload;
          if (typeof payload === 'object') {
            parsedPayload.eventName = eventName;
          } else {
            parsedPayload = { eventName, data: payload };
          }

          emit(parsedPayload);
        }));

      return () => {};
    });

  const channel = yield call(createChannel);

  while (true) {
    const payload = yield take(channel);

    yield events[payload.eventName](payload);
  }
}
