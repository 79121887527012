import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography/Typography';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { withTranslation } from 'react-i18next';

import Initials from '../domain/Initials';
import StateIndicator from '../containers/StateIndicator';
import List from '../../main/components/List';
import colors from '../../main/themes/colors';

const EmptySearch = styled(Typography)`
  padding: 30px 15px;
  text-align: center;
`;

const Container = styled.div`
  flex: 1;
  max-height: ${({ full }) => full ? '100%' : 'calc(100% - 132px)'};
  overflow-y: scroll;

  li {
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 0;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.primary} !important;
`;

const CallButton = styled(IconButton)`
  margin-right: 15px !important;
`;

const SearchResults = ({ t, full, results, searchAction, SearchResultsButtons }) => {
  const navigate = useNavigate();

  if (!results.length) {
    return <EmptySearch>{t('emptySearch')}</EmptySearch>;
  }

  const call = number => searchAction ? searchAction(number) : navigate(`/dialer?call=${encodeURIComponent(number)}`);

  return (
    <Container full={full}>
      <List>
        {results.map(contact => contact.numbers.map(({ number }) => (
          <ListItem key={`${contact.uuid || contact.sourceId}-${number}`}>
            <StateIndicator contact={contact} />
            <ListItemAvatar>
              <StyledAvatar>
                {Initials.parseFromContact(contact)}
              </StyledAvatar>
            </ListItemAvatar>
            <ListItemText primary={contact.name} secondary={number} />
            <ListItemSecondaryAction>
              {SearchResultsButtons ? <SearchResultsButtons contact={contact} /> : (
                <CallButton color="secondary" edge="end" onClick={() => call(number)}>
                  <CallIcon />
                </CallButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )))}
      </List>
    </Container>
  );
};

export default withTranslation('contact')(SearchResults);
