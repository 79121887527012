import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import Pause from '@mui/icons-material/Pause';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { login, logout, pause, resume } from '../actions/agentActions';
import Header from '../../main/components/Header';
import List from '../../main/components/List';

const Item = styled(ListItem)`
  &.MuiListItem-gutters {
    padding-left: 82px;
    padding-right: 82px;
  }
`;

const ItemContent = ({ Icon, disabled = false, label, onClick = () => {} }) => (
  <Item button disabled={disabled} onClick={onClick}>
    <ListItemAvatar>
      <Avatar>
        <Icon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={label} />
  </Item>
);

const Agent = ({ connected, active, actions, t }) => (
  <>
    <Header label={t('agent')} />

    <List>
      <ItemContent
        disabled={!connected}
        Icon={Pause}
        label={active ? t('pause') : t('resume')}
        onClick={connected ? (active ? actions.pause : actions.resume) : null}
      />

      <ItemContent
        Icon={ExitToApp}
        label={connected ? t('logout') : t('login')}
        onClick={connected ? actions.logout : actions.login}
      />
    </List>
  </>
);

const mapStateToProps = state => ({
  connected: state.agent.connected,
  active: state.agent.active,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logout, login, pause, resume }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('agent')(Agent)));
