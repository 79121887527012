export default {
  hold: 'Mettre en attente',
  unhold: 'Reprendre',
  mute: 'Couper le micro',
  unmute: 'Activer le micro',
  calling: 'Appel en cours',
  incomingCall: 'Appel entrant',
  callEnded: 'Appel terminé',
  callError: 'Une erreur est survenue durant l\'appel',
  transfer: 'Transfert',
  contacts: 'Contacts',
  directTransfer: 'Direct',
  indirectTransfer: 'Indirect',
  cancelIndirectTransfer: 'Annuler',
  confirmIndirectTransfer: 'Confirmer',
  startRecording: 'Enregistrer',
  stopRecording: 'Arrêter l\'enregistrement',
  searchInWazo: 'Saisir un nom ou un numéro',
  transferInWazo: 'Saisir nom ou numéro à transférer',
};
