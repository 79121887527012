export const START_LISTENING_CALL_EVENTS = 'call/START_LISTENING_CALL_EVENTS';
export const CALL_NUMBER = 'call/CALL_NUMBER';
export const HANGUP_CALL = 'call/HANGUP_CALL';
export const CALL_ERROR = 'call/CALL_ERROR';
export const ON_INVITE = 'call/ON_INVITE';
export const CALL_MADE = 'call/CALL_MADE';
export const CALL_HOLD = 'call/CALL_HOLD';
export const CALL_RESUME = 'call/CALL_RESUME';
export const CALL_HELD = 'call/CALL_HELD';
export const CALL_RESUMED = 'call/CALL_RESUMED';
export const CALL_ENDED = 'call/CALL_ENDED';
export const CALL_PROGRESS = 'call/CALL_PROGRESS';
export const CALL_REMOTLY_ACCEPTED = 'call/CALL_REMOTLY_ACCEPTED';
export const CALL_MUTE = 'call/CALL_MUTE';
export const CALL_UNMUTE = 'call/CALL_UNMUTE';
export const CALL_MUTED = 'call/CALL_MUTED';
export const CALL_UNMUTED = 'call/CALL_UNMUTED';
export const CALL_SEND_DTMF = 'call/CALL_SEND_DTMF';
export const CALL_LOCALLY_ACCEPTED = 'call/CALL_LOCALLY_ACCEPTED';
export const UPDATE_CALL_SESSION = 'call/UPDATE_CALL_SESSION';
export const REJECT_CALL = 'call/REJECT_CALL';
export const DIRECT_TRANSFER = 'call/DIRECT_TRANSFER';
export const CREATE_INDIRECT_TRANSFER = 'call/CREATE_INDIRECT_TRANSFER';
export const INDIRECT_TRANSFER_CALL_MADE = 'call/INDIRECT_TRANSFER_CALL_MADE';
export const INDIRECT_TRANSFER_CALL_UPDATED = 'call/INDIRECT_TRANSFER_CALL_UPDATED';
export const CANCEL_INDIRECT_TRANSFER = 'call/CANCEL_INDIRECT_TRANSFER';
export const ON_INDIRECT_TRANSFER_DONE = 'call/ON_INDIRECT_TRANSFER_DONE';
export const CONFIRM_INDIRECT_TRANSFER = 'call/CONFIRM_INDIRECT_TRANSFER';
export const START_RECORDING = 'call/START_RECORDING';
export const STOP_RECORDING = 'call/STOP_RECORDING';

export const listenToCallEvents = () => ({
  type: START_LISTENING_CALL_EVENTS,
});

export const call = number => ({
  type: CALL_NUMBER,
  payload: { number },
});

export const hangup = () => ({
  type: HANGUP_CALL,
});

export const onInvite = callSession => ({
  type: ON_INVITE,
  payload: { callSession },
});

export const acceptCall = () => ({
  type: CALL_LOCALLY_ACCEPTED,
});

export const rejectCall = () => ({
  type: REJECT_CALL,
});

export const onCallError = (error, technicalError) => ({
  type: CALL_ERROR,
  payload: { error, technicalError },
});

export const onCallMade = callSession => ({
  type: CALL_MADE,
  payload: { callSession },
});

export const onCallEnded = callSession => ({
  type: CALL_ENDED,
  payload: { callSession },
});

export const onCallHeld = () => ({
  type: CALL_HELD,
});

export const onCallResumed = () => ({
  type: CALL_RESUMED,
});

export const hold = () => ({
  type: CALL_HOLD,
});

export const unhold = () => ({
  type: CALL_RESUME,
});

export const onCallProgress = () => ({
  type: CALL_PROGRESS,
});

export const onCallAccepted = () => ({
  type: CALL_REMOTLY_ACCEPTED,
});

export const updateCallSession = callSession => ({
  type: UPDATE_CALL_SESSION,
  payload: { callSession },
});

export const mute = () => ({
  type: CALL_MUTE,
});

export const unmute = () => ({
  type: CALL_UNMUTE,
});

export const onCallMuted = () => ({
  type: CALL_MUTED,
});

export const onCallUnMuted = () => ({
  type: CALL_UNMUTED,
});

export const sendDTMF = value => ({
  type: CALL_SEND_DTMF,
  payload: { value },
});

export const directTransfer = number => ({
  type: DIRECT_TRANSFER,
  payload: { number },
});

export const indirectTransfer = number => ({
  type: CREATE_INDIRECT_TRANSFER,
  payload: { number },
});

export const cancelIndirectTransfer = () => ({
  type: CANCEL_INDIRECT_TRANSFER,
});

export const confirmIndirectTransfer = () => ({
  type: CONFIRM_INDIRECT_TRANSFER,
});

export const onIndirectTransferCallMade = indirectCall => ({
  type: INDIRECT_TRANSFER_CALL_MADE,
  payload: { indirectCall },
});

export const onIndirectTransferCallUpdated = indirectCall => ({
  type: INDIRECT_TRANSFER_CALL_UPDATED,
  payload: { indirectCall },
});

export const onIndirectTransferDone = () => ({
  type: ON_INDIRECT_TRANSFER_DONE,
});

export const startRecording = () => ({
  type: START_RECORDING,
});

export const stopRecording = () => ({
  type: STOP_RECORDING,
});
