import { put, takeEvery, select } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';

import {
  AGENT_LOAD_START,
  AGENT_LOGIN_START,
  AGENT_LOGOUT_START,
  AGENT_PAUSE_START,
  AGENT_RESUME_START,
  loadSuccess,
  loginSuccess,
  logoutSuccess,
  pauseSuccess,
  resumeSuccess,
} from '../actions/agentActions';
import { bindEvents } from '../../main/utils/sagas';

export const MIN_ENGINE_VERSION_LINEID_LOGIN = '20.11';
export const MIN_ENGINE_VERSION_STATIC_ACTIONS = '20.16';

const canUseStaticActions = session => session?.hasEngineVersionGte?.(MIN_ENGINE_VERSION_STATIC_ACTIONS);
const canUseLineIDLogin = session => session?.hasEngineVersionGte?.(MIN_ENGINE_VERSION_LINEID_LOGIN);

const agentEvents = {
  [Wazo.Websocket.AGENT_STATUS_UPDATE]: function* (payload) {
    yield onAgentUpdate(payload.data);
  },
  [Wazo.Websocket.AGENT_PAUSED]: function* ({ data }) {
    yield onAgentPaused(data);
  },
  [Wazo.Websocket.AGENT_UNPAUSED]: function* ({ data }) {
    yield onAgentResumed(data);
  },
};

function* onLoad() {
  const {
    user: { session },
  } = yield select();
  const useStaticActions = canUseStaticActions(session);

  let payload;

  try {
    if (useStaticActions) {
      payload = yield Wazo.api.agentd.getStatus();
    } else {
      const agentId = session?.profile?.agent?.id;
      payload = agentId ? yield Wazo.api.agentd.getAgent(agentId) : null;
    }

    yield put(loadSuccess(payload));
  } catch (_) {
    // Nothing to do
  }

  yield bindEvents(agentEvents, Wazo.Websocket);
}

function* onLogin() {
  const {
    user: { session },
    agent: { agent },
  } = yield select();
  const useLineIDLogin = canUseLineIDLogin(session);
  const sipLine = session.primaryWebRtcLine();
  if (!sipLine) {
    return;
  }

  const { line } = sipLine;
  let payload;

  try {
    if (useLineIDLogin) {
      payload = yield Wazo.api.agentd.loginWithLineId(line.id);
    } else {
      const { context, exten } = line?.extensions[0] || {};
      const agentNumber = agent.number;

      payload = yield Wazo.auth.agentd.login(agentNumber, context, exten);
    }

    put(loginSuccess(payload));
  } catch (_) {
    // Nothing to do
  }
}

function* handleApiCall(staticMethod, method) {
  const {
    user: { session },
    agent: { agent },
  } = yield select();
  const useStaticActions = canUseStaticActions(session);
  const agentNumber = agent.number;

  try {
    if (useStaticActions) {
      yield Wazo.api.agentd[staticMethod]();
    } else {
      yield Wazo.api.agentd[method](agentNumber);
    }
  } catch (_) {
    // Nothing to do
  }
}

function* onLogout() {
  yield handleApiCall('staticLogout', 'logout');
  // logoutSuccess will be fired by the WS event
}

function* onPause() {
  yield handleApiCall('staticPause', 'pause');
  // pauseSuccess will be fired by the WS event
}

function* onResume() {
  yield handleApiCall('staticResume', 'resume');
  // resumeSuccess will be fired by the WS event
}

function* onAgentUpdate({ agent_id: agentId, status }) {
  const {
    agent: { agent },
  } = yield select();

  if (agentId !== agent.id) {
    return;
  }

  if (status === 'logged_in') {
    yield put(loginSuccess());
  }
  if (status === 'logged_out') {
    yield put(logoutSuccess());
  }
}

function* onAgentPaused({ agent_id: agentId }) {
  const {
    agent: { agent },
  } = yield select();
  if (agentId !== agent.id) {
    return;
  }

  yield put(pauseSuccess());
}

function* onAgentResumed({ agent_id: agentId }) {
  const {
    agent: { agent },
  } = yield select();
  if (agentId !== agent.id) {
    return;
  }

  yield put(resumeSuccess());
}

export default [
  takeEvery(AGENT_LOAD_START, onLoad),
  takeEvery(AGENT_LOGIN_START, onLogin),
  takeEvery(AGENT_LOGOUT_START, onLogout),
  takeEvery(AGENT_PAUSE_START, onPause),
  takeEvery(AGENT_RESUME_START, onResume),
];
