import i18n from 'i18next';

import { ENABLE_CARD, INTEGRATION_CONFIG_RETRIEVED } from '../actions/bridgeActions';
import { DISPLAY_ERROR, HIDE_ERROR, UPDATE_LANGUAGE } from '../actions/mainActions';

const initialState = {
  hasCard: false,
  error: null,
  integrationConfig: {
    language: localStorage.getItem('language') || i18n.language,
  },
};

export const PRESENTIAL_COLORS = {
  GREEN: '#88CD78',
  YELLOW: '#ffbb00',
  RED: '#CC0214',
  GRAY: '#ccc',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE_CARD:
      return { ...state, hasCard: true };

    case DISPLAY_ERROR:
      return { ...state, error: action.error };

    case HIDE_ERROR:
      return { ...state, error: null };

    case INTEGRATION_CONFIG_RETRIEVED:
      return {
        ...state,
        integrationConfig: {
          ...state.integrationConfig,
          ...action.payload.config,
        },
      };

    case UPDATE_LANGUAGE:
      return {
        ...state,
        integrationConfig: {
          ...(state.integrationConfig || {}),
          language: action.payload.language,
        },
      };

    default:
      return state;
  }
}
