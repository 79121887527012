import { FETCH_CALL_LOGS_SUCCESS } from '../actions/callLogActions';

const initialState = {
  callLogs: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_CALL_LOGS_SUCCESS:
      return { ...state, callLogs: action.payload.callLogs };

    default:
      return state;
  }
}
