export default {
  spacing: {
    tiny: 5,
    small: 10,
    medium: 15,
    large: 20,
    larger: 25,
    big: 30,
    huge: 40,
    gigantic: 50,
  },
  icons: {
    xsmall: 12,
    smaller: 15,
    small: 19,
    mediumSmall: 25,
    medium: 30,
    large: 40,
    huge: 42,
  },
  breakpoint: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  borderRadius: 5,
  card: {
    activity: {
      height: 57,
    },
    phonebookContact: {
      height: 58,
    },
    search: {
      height: 35,
    },
  },
  navigation: 60,
  sidebar: 315,
  search: 359,
  lineStatus: 16,
  dialer: {
    key: 30,
    description: 15,
  },
  infoBanner: 43,
  infoBannerSmall: 26,
};
