import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import { changeDialerValue } from '../actions/dialerActions';
import { sendDTMF, call } from '../../call/actions/callActions';

import Keypad from './Keypad';

export const Dialer = ({ onCall, actions, keypadNumber }) => {
  const { search } = useLocation();
  const callNumber = new URLSearchParams(search).get('call');

  useEffect(() => {
    if (callNumber) {
      actions.call(callNumber);
    }
  }, [callNumber]);

  return (
    <Keypad
      call={actions.call}
      number={keypadNumber}
      onChange={actions.changeDialerValue}
      searchAction={number => actions.call(number)}
      sendDTMF={onCall ? actions.sendDTMF : null}
    />
  );
};

const mapStateToProps = state => ({
  keypadNumber: state.dialer.keypadNumber,
  onCall: state.call.ringing || state.call.inCall,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ changeDialerValue, sendDTMF, call }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Dialer));
