import {
  AGENT_LOAD_SUCCESS,
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGOUT_SUCCESS,
  AGENT_PAUSE_SUCCESS,
  AGENT_RESUME_SUCCESS,
} from '../actions/agentActions';

const initialState = {
  agent: null,
  active: false,
  connected: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AGENT_LOAD_SUCCESS:
      return {
        ...state,
        connected: action.payload.agent.logged,
        active: !action.payload.agent.paused,
        agent: action.payload.agent,
      };

    case AGENT_LOGIN_SUCCESS:
      return { ...state, connected: true };

    case AGENT_LOGOUT_SUCCESS:
      return { ...state, connected: false, active: true };

    case AGENT_PAUSE_SUCCESS:
      return { ...state, active: false };

    case AGENT_RESUME_SUCCESS:
      return { ...state, active: true };

    default:
      return state;
  }
}
