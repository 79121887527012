import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import colors from '../../main/themes/colors';

const Duration = styled(Typography)`
  && {
    color: ${colors.secondary};
    text-align: center;
  }
`;

const CallDuration = ({ answerTime }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const exceedsOneHour = elapsedTime > 60 * 60;
  const duration = moment.duration(elapsedTime, 'seconds');
  const formattedDuration = moment.utc(duration.as('milliseconds')).format(exceedsOneHour ? 'HH:mm:ss' : 'mm:ss');

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((Date.now() - answerTime) / 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [answerTime]);

  return <Duration className="duration" component="p">{formattedDuration}</Duration>;
};

export default React.memo(CallDuration);
