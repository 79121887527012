import {
  CALL_NUMBER,
  HANGUP_CALL,
  CALL_ERROR,
  CALL_MADE,
  CALL_ENDED,
  CALL_MUTED,
  CALL_UNMUTED,
  CALL_HELD,
  CALL_RESUMED,
  ON_INVITE,
  CALL_REMOTLY_ACCEPTED,
  CALL_LOCALLY_ACCEPTED,
  UPDATE_CALL_SESSION,
  INDIRECT_TRANSFER_CALL_MADE,
  ON_INDIRECT_TRANSFER_DONE,
  INDIRECT_TRANSFER_CALL_UPDATED,
} from '../actions/callActions';
import { SKIP_CARD } from '../../card/actions/cardActions';

export const OUTGOING = 'outgoing';
export const INCOMING = 'incoming';

const initialState = {
  accepted: false,
  currentNumber: null,
  currentContact: null,
  currentCallSession: null,
  direction: null,
  ringing: null,
  ended: false,
  locallyAccepted: false,
  indirectTransferCall: null,
  inCall: false,
  held: false,
  muted: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CALL_NUMBER:
      return {
        ...state,
        accepted: false,
        locallyAccepted: false,
        inCall: false,
        ringing: true,
        ended: false,
        direction: OUTGOING,
        currentNumber: action.payload.number,
      };

    case HANGUP_CALL:
      return { ...state, inCall: false, ringing: false, ended: true };

    case CALL_ENDED:
      return { ...state, inCall: false, ringing: false, ended: true, accepted: false, locallyAccepted: false };

    case CALL_MADE:
      return { ...state, inCall: false, ended: false, accepted: false, currentCallSession: action.payload.callSession };

    case ON_INVITE:
      return {
        ...state,
        ended: false,
        inCall: false,
        accepted: false,
        locallyAccepted: false,
        currentCallSession: action.payload.callSession,
        ringing: true,
        direction: INCOMING,
      };

    case CALL_ERROR:
      return { ...state, inCall: false, accepted: false, error: action.payload.error };

    case UPDATE_CALL_SESSION:
      // Updating currentCallSession so we can have `answerTime` or `callId`
      return { ...state, currentCallSession: action.payload.callSession };

    case CALL_REMOTLY_ACCEPTED:
      return { ...state, ringing: false, inCall: true, accepted: true };

    case CALL_LOCALLY_ACCEPTED:
      return { ...state, locallyAccepted: true };

    case CALL_MUTED:
      return { ...state, muted: true };

    case CALL_UNMUTED:
      return { ...state, muted: false };

    case CALL_HELD:
      return { ...state, held: true };

    case CALL_RESUMED:
      return { ...state, held: false };

    case INDIRECT_TRANSFER_CALL_MADE:
    case INDIRECT_TRANSFER_CALL_UPDATED:
      return { ...state, indirectTransferCall: action.payload.indirectCall };

    case ON_INDIRECT_TRANSFER_DONE:
      return { ...state, indirectTransferCall: null };

    case SKIP_CARD:
      return { ...initialState };

    default:
      return state;
  }
}
