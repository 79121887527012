export const LOGIN_REQUEST = 'user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';
export const AUTHENTICATION_REQUEST = 'user/AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'user/AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'user/AUTHENTICATION_FAILURE';
export const IS_AUTHENTICATING = 'user/IS_AUTHENTICATING';
export const ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE = 'user/ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE';
export const LOGOUT_REQUEST = 'user/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';

export const login = (username, password, useLdap) => ({
  type: LOGIN_REQUEST,
  payload: { username, password, useLdap },
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const loginSuccess = session => ({
  type: LOGIN_SUCCESS,
  payload: { session },
});

export const loginFailed = (error, technicalError) => ({
  type: LOGIN_FAILURE,
  payload: { error, technicalError },
});

export const authenticate = () => ({
  type: AUTHENTICATION_REQUEST,
});

export const authenticationSuccess = session => ({
  type: AUTHENTICATION_SUCCESS,
  payload: { session },
});

export const authenticationFailed = (error, technicalError) => ({
  type: AUTHENTICATION_FAILURE,
  payload: { error, technicalError },
});

export const setIsAuthenticating = () => ({
  type: IS_AUTHENTICATING,
});

export const changeRequiredSubscriptionType = subscriptionType => ({
  type: ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE,
  payload: { subscriptionType },
});
