/* globals sforce */
import './styles.css';
import {
  BRIDGE_CONFIG_RETRIEVED,
  BRIDGE_AUTHENTICATED,
  BRIDGE_LOGGED_OUT,
  BRIDGE_ON_CLICK_TO_CALL,
  BRIDGE_CALL_ENDED,
  BRIDGE_CALL_INCOMING,
  BRIDGE_CARD_CREATED,
  BRIDGE_CREATE_OR_UPDATE_CARD,
  BRIDGE_ENABLE_CARD,
  BRIDGE_CHANGE_LANGUAGE,
  BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE,
  BRIDGE_DISPLAY_ERROR,
  BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND,
  BRIDGE_SET_CARD_CONTENT,
  BRIDGE_CALL_OUTGOING,
  BRIDGE_DISPLAY_LINKED_ENTITY,
  BRIDGE_SEARCH_ENTITIES,
  BRIDGE_FOUND_ENTITIES,
  BRIDGE_SAVING_CARD,
  BRIDGE_SAVED_CARD,
  DISPLAY_BOTH_ENTITY_SELECTION,
  BRIDGE_ENABLE_CLICK_TO_CALL,
  BRIDGE_DISABLE_CLICK_TO_CALL,
  SDK_CLICK_TO_CALL,
  BRIDGE_ON_CALL_ESTABLISHED,
} from '../../main/sagas/bridgeSagas';
import { sendMessage } from '../../main/utils/integration';
import { LINKED_CONTACT_FIELD, LINKED_RELATION_FIELD, NEW_CONTACT_VALUE } from '../../card/containers/EntitySelection';

import { parseEntities } from './utils';
import {
  displayPanel,
  createLogCall,
  updateCallLog,
  getCallCenterSettings,
  searchAndPopContact,
  displayRecord,
  refreshView,
  createContact,
  setConfig,
} from './actions';

const REQUIRED_SUBSCRIPTION_TYPE = 3;

const onInit = () => {
  sendMessage(BRIDGE_ENABLE_CARD);

  if (document.domain === 'localhost') {
    // Fake event to be able to work outside salesforce
    sendMessage(BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE, { subscriptionType: REQUIRED_SUBSCRIPTION_TYPE });
    sendMessage(BRIDGE_CONFIG_RETRIEVED, {
      config: {
        server: 'stack.dev.wazo.io',
        shouldDisplayLinkedEntities: DISPLAY_BOTH_ENTITY_SELECTION,
        allowContactCreation: true,
      },
    });
  }

  // Retrieve settings like server name
  getCallCenterSettings().then(config => {
    setConfig(config);

    localStorage.setItem('settings', JSON.stringify(config));
    config.shouldDisplayLinkedEntities = DISPLAY_BOTH_ENTITY_SELECTION;
    config.allowContactCreation = true;

    sendMessage(BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE, { subscriptionType: REQUIRED_SUBSCRIPTION_TYPE });
    sendMessage(BRIDGE_CONFIG_RETRIEVED, { config });

    sendMessage(BRIDGE_CHANGE_LANGUAGE, { language: config.language });
  });

  // Called on click on a number in the salesforce interface
  sforce.opencti.onClickToDial({
    listener: result => {
      displayPanel();

      sendMessage(BRIDGE_ON_CLICK_TO_CALL, { number: result.number });
    },
  });

  sforce.opencti.notifyInitializationComplete();
};

const searchEntities = async (query, fieldId, callType = sforce.opencti.CALL_TYPE.INBOUND) => {
  const rawResults = await searchAndPopContact(query, callType, true);

  delete rawResults.SCREEN_POP_DATA;
  const isContact = fieldId === LINKED_CONTACT_FIELD;

  return parseEntities(Object.values(rawResults)).filter(result => isContact ? result.human : !result.human);
};

const searchAllEntities = async (callSession, callType) => {
  try {
    const [contacts, relations] = await Promise.all([
      searchEntities(callSession.number, LINKED_CONTACT_FIELD, callType),
      searchEntities(callSession.number, LINKED_RELATION_FIELD, callType),
    ]);
    let entity;

    sendMessage(BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND, { linkedEntities: contacts, fieldId: LINKED_CONTACT_FIELD });
    sendMessage(BRIDGE_MULTIPLE_LINKED_ENTITIES_FOUND, { linkedEntities: relations, fieldId: LINKED_RELATION_FIELD });

    // Results found
    if (contacts.length) {
      entity = contacts[0];
      sendMessage(BRIDGE_SET_CARD_CONTENT, { field: LINKED_CONTACT_FIELD, value: entity });
      if (callType === 'inbound') {
        displayRecord(entity.Id);
      }
    }

    if (relations.length) {
      sendMessage(BRIDGE_SET_CARD_CONTENT, { field: LINKED_RELATION_FIELD, value: relations[0] });
    }
  } catch (e) {
    sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
  }
};

const onCallOutgoing = async callSession => {
  await searchAllEntities(callSession, sforce.opencti.CALL_TYPE.OUTBOUND);
};

const createOrUpdateTask = async (callSession, direction, userExtension, cardContent) => {
  if (!callSession) {
    return;
  }

  sendMessage(BRIDGE_SAVING_CARD);

  let task;

  // Create contact if needed
  if (cardContent.linkedContact && cardContent.linkedContact.wazoId === NEW_CONTACT_VALUE) {
    const { phone, firstname, lastname } = cardContent.linkedContact;
    cardContent.linkedContact = await createContact(phone, firstname, lastname);
  }

  if (!cardContent.cardId) {
    const { linkedContact, linkedRelation } = cardContent;
    try {
      if (linkedContact || linkedRelation) {
        task = await createLogCall(callSession, direction, userExtension, cardContent);
      }

      if (task) {
        sendMessage(BRIDGE_CARD_CREATED, { id: task.Id });
      }
    } catch (e) {
      sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
    }

    if (task) {
      task.cardId = task.Id;
      task.title = cardContent.title;
      task.note = cardContent.note;
      task.linkedContact = cardContent.linkedContact;
      task.linkedRelation = cardContent.linkedRelation;
    }
  } else {
    task = cardContent;
  }

  if (task && task.cardId) {
    try {
      await updateCallLog(callSession, direction, userExtension, task);
    } catch (e) {
      sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
    }
  }

  if (task && task.linkedRelation) {
    displayRecord(task.linkedRelation.Id);
  } else if (task && task.linkedContact) {
    displayRecord(task.linkedContact.Id);
  }

  sendMessage(BRIDGE_SAVED_CARD);

  refreshView();
};

const onMessage = async event => {
  switch (event.data.type) {
    case BRIDGE_AUTHENTICATED:
    case BRIDGE_ENABLE_CLICK_TO_CALL:
      sforce.opencti.enableClickToDial();
      break;
    case BRIDGE_LOGGED_OUT:
    case BRIDGE_DISABLE_CLICK_TO_CALL:
      sforce.opencti.disableClickToDial();
      break;
    case BRIDGE_CALL_ENDED: {
      const { callSession, direction, userExtension, content } = event.data;
      if (!callSession.answered) {
        // Do not create log for not answered calls
        return;
      }

      await createOrUpdateTask(callSession, direction, userExtension, content);
      break;
    }
    case BRIDGE_CALL_INCOMING:
      displayPanel();
      break;
    case BRIDGE_ON_CALL_ESTABLISHED:
      searchAllEntities(event.data.callSession, sforce.opencti.CALL_TYPE.INBOUND);
      break;
    case BRIDGE_CALL_OUTGOING:
      await onCallOutgoing(event.data.callSession);
      break;
    case BRIDGE_CREATE_OR_UPDATE_CARD: {
      const { callSession, direction, userExtension, content } = event.data;

      await createOrUpdateTask(callSession, direction, userExtension, content);
      break;
    }
    case BRIDGE_DISPLAY_LINKED_ENTITY:
      await displayRecord(event.data.linkedEntityId);
      break;
    case BRIDGE_SEARCH_ENTITIES: {
      const { query, fieldId } = event.data;
      const entities = await searchEntities(query, fieldId);
      sendMessage(BRIDGE_FOUND_ENTITIES, { entities, fieldId });
      break;
    }
    case SDK_CLICK_TO_CALL:
      sendMessage(BRIDGE_ON_CLICK_TO_CALL, { number: event.data.extension });
      break;
    default:
      break;
  }
};

window.addEventListener('message', onMessage, false);

const script = document.createElement('script');
script.onload = onInit;
script.src = '/assets/js/opencti.js';

document.head.appendChild(script);
