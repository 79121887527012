import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import { withTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { updateLanguage } from '../../main/actions/mainActions';

const Container = styled.div`
  padding-top: 30px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
  }
`;

const StyledForm = styled(FormControl)`
  && {
    display: block;
    width: 80%;
    margin: 0 auto !important;
    & > div {
      width: 100%;
    }
  }
`;

const Language = ({ t, actions, language }) => (
  <Container className="settings-container">
    <Title className="setting-title" component="h1">
      {t('language')}
    </Title>

    <StyledForm variant="outlined">
      <Select onChange={e => actions.updateLanguage(e.target.value)} value={language}>
        <MenuItem value="fr">{t('french')}</MenuItem>
        <MenuItem value="en">{t('english')}</MenuItem>
      </Select>
    </StyledForm>
  </Container>
);

const mapStateToProps = state => ({
  language: state.main.integrationConfig?.language?.toLowerCase(),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateLanguage }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('settings')(Language)));
