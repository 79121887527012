import React, { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';

import { searchContact } from '../actions/contactActions';
import SearchResults from '../components/SearchResults';
import colors from '../../main/themes/colors';

const SearchError = styled(Typography)`
  padding: 30px 15px;
  text-align: center;
  color: #ef5350;
`;

const Header = styled.div`
  height: 70px;
  background: ${colors.secondGrey};
  display: flex;
  align-items: center;
  padding: 0 30px;
`;

const Progress = styled(CircularProgress)`
  margin: 10px auto;
  display: block !important;
`;

const Contacts = ({ searchQuery, searchResults, actions, searching, searchError, t }) => {
  const location = useLocation();

  useEffect(() => {
    // Reset search when changing URL
    actions.searchContact('');
  }, [location]);

  const search = debounce((event) => {
    const query = event.target.value;
    actions.searchContact(query);
  }, 300);

  return (
    <>
      <Header>
        <TextField
          fullWidth
          id="outlined-full-width"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={search}
          placeholder={t('searchContact')}
        />
      </Header>

      {searchError && <SearchError>{t('searchError')}</SearchError>}

      {searchQuery.length > 2 && (searching ? <Progress size={50} /> : (
        <SearchResults results={searchResults} />
      ))}
    </>
  );
};

const mapStateToProps = state => ({
  searchResults: state.contact.searchResults,
  searching: state.contact.searching,
  searchError: state.contact.searchError,
  searchQuery: state.contact.searchQuery,
  updatedAt: state.contact.updatedAt,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchContact }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('contact')(Contacts)));
