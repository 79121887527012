export default {
  en: {
    agent: require('./en/agent').default,
    user: require('./en/user').default,
    settings: require('./en/settings').default,
    card: require('./en/card').default,
    call: require('./en/call').default,
    dialer: require('./en/dialer').default,
    contact: require('./en/contact').default,
    callLogs: require('./en/callLogs').default,
  },
  fr: {
    agent: require('./fr/agent').default,
    user: require('./fr/user').default,
    settings: require('./fr/settings').default,
    card: require('./fr/card').default,
    call: require('./fr/call').default,
    dialer: require('./fr/dialer').default,
    contact: require('./fr/contact').default,
    callLogs: require('./fr/callLogs').default,
  },
};
