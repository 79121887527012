import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography/Typography';

import { saveCard, skipCard, setValue, displayLinkedEntity } from '../actions/cardActions';
import colors from '../../main/themes/colors';
import { DISPLAY_BOTH_ENTITY_SELECTION } from '../../main/sagas/bridgeSagas';

import EntitySelection, { LINKED_CONTACT_FIELD, LINKED_RELATION_FIELD } from './EntitySelection';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledTextField = styled(TextField)``;

const StyledTextArea = styled(StyledTextField)`
  && {
    flex: 4;
    margin-bottom: 5px !important;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

const FormButton = styled(Button)`
  && {
    display: block;
    margin: 0 10px;
    border-radius: 28px;
  }
`;

const CancelButton = styled(FormButton)``;

const SaveButton = styled(FormButton)``;

const StyledError = styled(Typography)`
  && {
    text-align: center;
    margin: 20px 0;
    color: ${colors.error};
  }
`;

const Buttons = ({ actions, ended, t, savingCard }) => {
  if (!ended) {
    return null;
  }

  return (
    <ButtonsContainer>
      <CancelButton onClick={actions.skipCard} variant="outlined">
        {t('cancel')}
      </CancelButton>
      <SaveButton disabled={savingCard} type="submit" variant="contained">
        {t('save')}
      </SaveButton>
    </ButtonsContainer>
  );
};

const Card = ({
  cardCallSession,
  error,
  actions,
  hasCard,
  ringing,
  ended,
  content,
  linkedContacts,
  linkedRelations,
  savingCard,
  t,
  wrapUpDuration,
  shouldDisplayLinkedEntities,
  allowContactCreation,
}) => {
  const [errors, setErrors] = useState({});
  const linkedContact = content.linkedContact ? content.linkedContact.wazoId : null;
  const linkedRelation = content.linkedRelation ? content.linkedRelation.wazoId : null;
  const wrapUpTimeout = useRef(null);

  useEffect(() => {
    if (!ended || content.title || content.note || !wrapUpDuration || +wrapUpDuration === 0) {
      return;
    }
    wrapUpTimeout.current = setTimeout(() => {
      actions.skipCard();
    }, +wrapUpDuration * 1000);
  }, [ended]);

  const performSubmit = e => {
    e.preventDefault();
    const newErrors = {};

    if (!content.title) {
      newErrors.title = true;
    }

    if (shouldDisplayLinkedEntities && !content[LINKED_CONTACT_FIELD]) {
      newErrors[LINKED_CONTACT_FIELD] = true;
    }

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return null;
    }

    return actions.saveCard(content, cardCallSession);
  };

  if (!hasCard || ringing) {
    return null;
  }

  const setCardValue = (name, val) => {
    actions.setValue(name, val);

    if (wrapUpTimeout.current) {
      clearTimeout(wrapUpTimeout.current);
    }
  };

  const onSelectEntity = (entity, field) => {
    setCardValue(field, entity);

    if (entity && entity.id && field === LINKED_CONTACT_FIELD) {
      actions.displayLinkedEntity(entity.id);
    }
  };

  return (
    <Form className="card-form" onSubmit={performSubmit}>
      {error && <StyledError>{error}</StyledError>}

      {shouldDisplayLinkedEntities && (
        <EntitySelection
          allowCreate={allowContactCreation}
          entities={linkedContacts}
          error={LINKED_CONTACT_FIELD in errors}
          label={t('entity')}
          name={LINKED_CONTACT_FIELD}
          onChange={entity => onSelectEntity(entity, LINKED_CONTACT_FIELD)}
          value={linkedContact}
        />
      )}

      {shouldDisplayLinkedEntities === DISPLAY_BOTH_ENTITY_SELECTION && (
        <EntitySelection
          entities={linkedRelations}
          label={t('relation')}
          name={LINKED_RELATION_FIELD}
          onChange={entity => onSelectEntity(entity, LINKED_RELATION_FIELD)}
          value={linkedRelation}
        />
      )}

      <StyledTextField
        autoComplete="off"
        className="title-field"
        error={'title' in errors}
        id="title"
        label={t('title')}
        onChange={event => setCardValue('title', event.target.value)}
        type="text"
        value={content.title}
      />

      <StyledTextArea
        className="note-field"
        id="text"
        label={t('note')}
        multiline
        onChange={event => setCardValue('note', event.target.value)}
        rows={5}
        value={content.note}
      />

      <Buttons actions={actions} ended={ended} savingCard={savingCard} t={t} />
    </Form>
  );
};

const mapStateToProps = state => ({
  currentCallSession: state.call.currentCallSession,
  hasCard: state.main.hasCard,
  authenticating: state.user.authenticating,
  error: state.user.error,
  ended: state.call.ended,
  ringing: state.call.ringing,
  linkedContacts: state.card.linkedEntities[LINKED_CONTACT_FIELD] || [],
  linkedRelations: state.card.linkedEntities[LINKED_RELATION_FIELD] || [],
  content: state.card.content,
  cardCallSession: state.card.callSession,
  savingCard: state.card.savingCard,
  wrapUpDuration: state.main.integrationConfig?.wrapUpDuration,
  shouldDisplayLinkedEntities: state.main.integrationConfig?.shouldDisplayLinkedEntities,
  allowContactCreation: state.main.integrationConfig?.allowContactCreation,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ skipCard, saveCard, setValue, displayLinkedEntity }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('card')(Card)));
