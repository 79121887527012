import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import Language from '@mui/icons-material/Language';
import Info from '@mui/icons-material/Info';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PermIdentity from '@mui/icons-material/PermIdentity';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { logout } from '../../user/actions/userActions';
import Header from '../../main/components/Header';
import List from '../../main/components/List';
import colors from '../../main/themes/colors';

const Arrow = styled(KeyboardArrowRight)`
  color: ${colors.avatar};
`;

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`;

const ItemContent = ({ Icon, label, onClick = () => {}, noMore }) => (
  <StyledListItem button onClick={onClick}>
    <ListItemAvatar>
      <Avatar>
        <Icon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={label} />

    {!noMore && <Arrow />}
  </StyledListItem>
);

const Settings = ({ actions, t }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header label={t('title')} />

      <List>
        <ItemContent Icon={PermIdentity} label={t('userState')} onClick={() => navigate('/user-state')} />

        <ItemContent Icon={Language} label={t('language')} onClick={() => navigate('/language')} />

        <ItemContent Icon={Info} label={t('information')} onClick={() => navigate('/information')} />

        <ItemContent
          Icon={PowerSettingsNew}
          label={t('logout')}
          noMore
          onClick={e => {
            e.preventDefault();
            actions.logout();
          }}
        />
      </List>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logout }, dispatch),
});

export default connect(
  () => ({}),
  mapDispatchToProps,
)(React.memo(withTranslation('settings')(Settings)));
